import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/"); // Redirect to the home page or any desired route
    };

    return (
        <div className="min-h-screen w-full flex items-center justify-center back-Img">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
                <h1 className="text-4xl font-bold text-primary-purple mb-4">Oops!</h1>
                <p className="text-lg text-gray-700 mb-6">
                    Something went wrong. We couldn't find the page you're looking for.
                </p>
                <button
                    onClick={handleGoHome}
                    className="bg-primary-blue text-white py-2 px-4 rounded hover:bg-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple"
                >
                    Go to Home
                </button>
            </div>
        </div>
    );
}
