import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import itemdata from "../data/itemdata"; // Import the data file
import Loader from "../components/Loader";
import Navbar from "./Navbar";
import CFooter from "./CFooter";

const EventShow = () => {
  const [loader, setLoader] = useState(false);
  const [eventData, setEventData] = useState([]);
  const navigate = useNavigate();
  const event = useLocation().pathname.split("/")[2].replace("%20", " ");

  useEffect(() => {
    fetchCarEvent();
    // eslint-disable-next-line
  }, []);
  const fetchCarEvent = () => {
    setLoader(true);
    try {
      // Filter items based on the brand from the URL
      const filteredItems = itemdata.filter((item) => item?.c_event === event);
      setEventData(filteredItems); // Set filtered data
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };
  const handleGoProduct = (id) => {
    navigate(`/shop/product/${id}`);
  };

  return (
    <>
      <div className="flex w-full justify-center  min-h-screen font-sans bg-white">
        {loader ? (
          <Loader loader={loader} />
        ) : (
          <div className="w-[425px] h-auto bg-white">
            <Navbar />
            <div className="bg-white py-4 px-3 mt-2">
              <h1 className="font-bold text-xl mb-2">{event}</h1>
              <hr className="mb-4" />
              <div className="grid grid-cols-2 gap-1 my-3">
                {eventData?.map((items, i) => (
                  <div
                    key={i}
                    className="overflow-hidden rounded-lg p-2 transform transition-transform duration-500 hover:scale-105"
                  >
                    <div onClick={() => handleGoProduct(items?._id?.$oid)}>
                      {/* Product Image */}
                      <div className="w-full h-autorelative cursor-pointer">
                        <img
                          className="w-full h-full object-contain rounded-xl transition-opacity duration-500 hover:opacity-90"
                          src={items?.c_multiImage[0]?.i_url}
                          alt={items?.c_name}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <CFooter />
          </div>
        )}
      </div>
    </>
  );
};

export default EventShow;
