import React, { useEffect } from "react";

const InspactLook = () => {
    useEffect(() => {
        // Disable right-click context menu
        const disableContextMenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener("contextmenu", disableContextMenu);

        // Disable F12, Ctrl+Shift+I, and other inspect element shortcuts
        const disableInspectShortcuts = (e) => {
            if (
                (e.ctrlKey && e.shiftKey && e.key === "I") ||
                (e.ctrlKey && e.shiftKey && e.key === "J") ||
                (e.ctrlKey && e.key === "U") ||
                e.key === "F12"
            ) {
                e.preventDefault();
            }
        };
        document.addEventListener("keydown", disableInspectShortcuts);

        // Cleanup event listeners on unmount
        return () => {
            document.removeEventListener("contextmenu", disableContextMenu);
            document.removeEventListener("keydown", disableInspectShortcuts);
        };
    }, []);
    return <div></div>;
};

export default InspactLook;