import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [cookieChoice, setCookieChoice] = useState(null);

  // Check for saved cookie preferences when component mounts
  useEffect(() => {
    const savedChoice = localStorage.getItem('cookieChoice');
    if (!savedChoice) {
      setShowBanner(true);
    } else if (savedChoice === 'accepted') {
      loadGoogleAnalytics(); // Load analytics if user already accepted cookies
    }
  }, []);

  // Function to load Google Analytics
  const loadGoogleAnalytics = () => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-44TWGSSRXK'; // Replace with your GA Tracking ID
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-44TWGSSRXK'); // Replace with your GA Tracking ID
  };

  // Handle Accept cookies
  const handleAccept = () => {
    localStorage.setItem('cookieChoice', 'accepted');
    setCookieChoice('accepted');
    setShowBanner(false);
    loadGoogleAnalytics(); // Call the function to load analytics
  };

  // Handle Reject cookies
  const handleReject = () => {
    localStorage.setItem('cookieChoice', 'rejected');
    setCookieChoice('rejected');
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="cookie-consent-banner bg-gray-800 text-white p-4 fixed bottom-0 w-full z-50">
          <p>
            We use third-party cookies to personalize your experience. You can accept or reject the use of these cookies.
          </p>
          <div className="flex space-x-4 mt-2">
            <button
              className="bg-green-500 px-4 py-2 rounded"
              onClick={handleAccept}
            >
              Accept
            </button>
            <button
              className="bg-red-500 px-4 py-2 rounded"
              onClick={handleReject}
            >
              Reject
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
