import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Navbar from "./Navbar";
import CFooter from "./CFooter";
import itemdata from "../data/itemdata"; // Import the data file

const Category = () => {
  const [loader, setLoader] = useState(false);
  const [eventData, setEventData] = useState([]);
  const navigate = useNavigate();
  const event = useLocation().pathname.split("/")[3].replaceAll("%20", " "); // Get the brand from the URL
  const [category, setCategory] = useState("");
  useEffect(() => {
    fetchCarEvent();
  }, []);

  const fetchCarEvent = () => {
    setLoader(true);
    try {
      // Filter items based on the brand from the URL
      const filteredItems = itemdata.filter((item) => item?.c_brand === event);
      setEventData(filteredItems); // Set filtered data
      setCategory(event); // Set the category name based on the brand
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleGoProduct = (id) => {
    navigate(`/shop/product/${id}`);
  };

  return (
    <>
      <div className="flex w-full justify-center min-h-screen font-sans bg-white">
        {loader ? (
          <Loader loader={loader} />
        ) : (
          <div className="w-[425px] h-auto bg-white">
            <Navbar />
            <div className="bg-white py-4 px-3 mt-2">
              <h1 className="font-bold text-xl mb-2">{category}</h1>
              <hr className="mb-4" />
              <div className="grid grid-cols-2 gap-1 my-3">
                {eventData &&
                  eventData.map((items, i) => (
                    <div
                      key={i}
                      className="overflow-hidden w-full rounded-lg p-2 transform transition-transform duration-500 hover:scale-105"
                    >
                      <div onClick={() => handleGoProduct(items?._id?.$oid)}>
                        <div className="w-full h-auto relative">
                          <img
                            className="w-full h-full object-cover rounded-xl transition-opacity duration-500 hover:opacity-90"
                            src={items.c_multiImage[0]?.i_url}
                            alt={items.c_name}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <CFooter />
          </div>
        )}
      </div>
    </>
  );
};

export default Category;
