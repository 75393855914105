import { Route, Routes } from "react-router-dom";
import "./App.css";
import ErrorPage from "./components/Error";
import { ToastContainer } from "react-toastify";
import MainHome from "./Client/MainHome";
import EventShow from "./Client/EventShow";
import Product from "./Client/Product";
import Category from "./Client/Category";
import CookieConsent from "./components/CookieConsent";

function App() {
  return (
    <div className="flex roboto-regular">
      <div className="flex ml-w-15 w-[100%]">
        <CookieConsent />
        <ToastContainer />
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route path="/" element={<MainHome />} />
          <Route path="/more/:id" element={<EventShow />} />
          <Route path="/more/category/:id" element={<Category />} />
          <Route path="/shop/product/:id" element={<Product />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
