const data = [
    {
        "_id": {
            "$oid": "66f5152c5fe50a80ec2c1bed"
        },
        "c_name": "Women Rapid-Dry Running Tracksuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337772151-1.jpg",
                "_id": {
                    "$oid": "66f5152c5fe50a80ec2c1bee"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337772151-2.jpg",
                "_id": {
                    "$oid": "66f5152c5fe50a80ec2c1bef"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337772151-3.jpg",
                "_id": {
                    "$oid": "66f5152c5fe50a80ec2c1bf0"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 857,
        "c_description": "Special technology:\nRapid-Dry\nAntimicrobial Finish\nProduct Design details:\nThis tracksuit consists of a jacket and a pair of joggers\nGrey solid jacket has a mock neck collar, full zip closure and long sleeves\nGrey solid mid-rise joggers has an elasticated waistband with drawstring closure, cuffed hems and two pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T08:02:52.459Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T08:02:52.459Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f5155a5fe50a80ec2c1bfb"
        },
        "c_name": "Full Coverage Bra with All Day Comfort",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337818693-1.jpg",
                "_id": {
                    "$oid": "66f5155a5fe50a80ec2c1bfc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337818693-2.jpg",
                "_id": {
                    "$oid": "66f5155a5fe50a80ec2c1bfd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337818693-3.jpg",
                "_id": {
                    "$oid": "66f5155a5fe50a80ec2c1bfe"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337818693-4.jpg",
                "_id": {
                    "$oid": "66f5155a5fe50a80ec2c1bff"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1249,
        "c_description": "Bring home this ultra-soft bra which has been meticulously designed to lend accurate definition to your bustline. Say goodbye to visible bra outlines when you wear it underneath your favourite attire.\n\nFeatures\nVANumber: BC_SeamlesBra_Black_XS\nBlack solid bra\nFull coverage\nNon-wired to retain natural lift and all-day comfort\nRemovable padding for an easy swap between padded and non-padded support\nSeamless cups\nRegular straps\nSlip-on\nFeatures: all day comfort",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T08:03:38.946Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T08:03:38.946Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f5157b5fe50a80ec2c1c07"
        },
        "c_name": "Women Relaxed Straight Leg Straight Fit High-Rise Wrinkle Free Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337851404-1.jpg",
                "_id": {
                    "$oid": "66f5157b5fe50a80ec2c1c08"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337851404-2.jpg",
                "_id": {
                    "$oid": "66f5157b5fe50a80ec2c1c09"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337851404-3.jpg",
                "_id": {
                    "$oid": "66f5157b5fe50a80ec2c1c0a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727337851404-4.jpg",
                "_id": {
                    "$oid": "66f5157b5fe50a80ec2c1c0b"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1099,
        "c_description": "Black knitted trousers\nStraight fit\nBrand Fit: relaxed straight leg\nHigh-rise\nLength: regular\nPattern: solid\nFlat-front, with no pleats design\nFeature: wrinkle free\n2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T08:04:11.746Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T08:04:11.746Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f539c8bff1d7f9e68d265a"
        },
        "c_name": "Sequinned Organza Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347143630-1.jpg",
                "_id": {
                    "$oid": "66f539c8bff1d7f9e68d265b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347143630-2.jpg",
                "_id": {
                    "$oid": "66f539c8bff1d7f9e68d265c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347143630-3.jpg",
                "_id": {
                    "$oid": "66f539c8bff1d7f9e68d265d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347143630-4.jpg",
                "_id": {
                    "$oid": "66f539c8bff1d7f9e68d265e"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 879,
        "c_description": "Design Details\nPink and gold-toned saree\nSolid saree with embroidered border border\nHas sequinned detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:39:04.111Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:39:04.111Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f539e4bff1d7f9e68d2666"
        },
        "c_name": "Orange Ethnic Woven Design Zari Paithani Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347172266-1.jpg",
                "_id": {
                    "$oid": "66f539e4bff1d7f9e68d2667"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347172266-2.jpg",
                "_id": {
                    "$oid": "66f539e4bff1d7f9e68d2668"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347172266-3.jpg",
                "_id": {
                    "$oid": "66f539e4bff1d7f9e68d2669"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347172266-4.jpg",
                "_id": {
                    "$oid": "66f539e4bff1d7f9e68d266a"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1259,
        "c_description": "Design Details\nOrange and gold-toned paithani saree\nEthnic Motifs Woven design saree with woven design border \nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:39:32.422Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:39:32.422Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53a01bff1d7f9e68d266d"
        },
        "c_name": "Floral Sequins and Thread Embroidered Saree with Blouse Piece",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347200866-1.jpg",
                "_id": {
                    "$oid": "66f53a01bff1d7f9e68d266e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347200866-2.jpg",
                "_id": {
                    "$oid": "66f53a01bff1d7f9e68d266f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347200866-3.jpg",
                "_id": {
                    "$oid": "66f53a01bff1d7f9e68d2670"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 2031,
        "c_description": "Design Details\nMagenta and gold-toned saree\nEmbellished saree with embroidered border \nHas embroidered detail\nDisclaimer\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:40:01.129Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:40:01.129Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53a1abff1d7f9e68d2673"
        },
        "c_name": "Embellished Sequinned Net Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347226469-1.jpg",
                "_id": {
                    "$oid": "66f53a1abff1d7f9e68d2674"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347226469-2.jpg",
                "_id": {
                    "$oid": "66f53a1abff1d7f9e68d2675"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347226469-3.jpg",
                "_id": {
                    "$oid": "66f53a1abff1d7f9e68d2676"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347226469-4.jpg",
                "_id": {
                    "$oid": "66f53a1abff1d7f9e68d2677"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1103,
        "c_description": "Design Details\nNude-coloured and silver-toned saree\nEmbellished saree with embellished border border\nHas sequinned detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:40:26.765Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:40:26.765Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53a39bff1d7f9e68d267a"
        },
        "c_name": "Peach-Coloured & Golden Floral Zari Silk Blend Banarasi Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347256975-1.jpg",
                "_id": {
                    "$oid": "66f53a39bff1d7f9e68d267b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347256975-2.jpg",
                "_id": {
                    "$oid": "66f53a39bff1d7f9e68d267c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347256975-3.jpg",
                "_id": {
                    "$oid": "66f53a39bff1d7f9e68d267d"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1899,
        "c_description": "Design Details\nPeach-coloured and gold-toned banarasi saree\nFloral woven design saree with woven design border\nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:40:57.304Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:40:57.304Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53cacc4cb3eb7481fdb0c"
        },
        "c_name": "Woven Design Zari Silk Blend Banarasi Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347884106-1.jpg",
                "_id": {
                    "$oid": "66f53cacc4cb3eb7481fdb0d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347884106-2.jpg",
                "_id": {
                    "$oid": "66f53cacc4cb3eb7481fdb0e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347884106-3.jpg",
                "_id": {
                    "$oid": "66f53cacc4cb3eb7481fdb0f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347884106-4.jpg",
                "_id": {
                    "$oid": "66f53cacc4cb3eb7481fdb10"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 559,
        "c_description": "Design Details\nNavy blue and gold-toned banarasi saree\nWoven design saree with woven design border \nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Normal",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:51:24.458Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:51:24.458Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53cf2c4cb3eb7481fdb18"
        },
        "c_name": "Woven Design Zari Pure Silk Paithani Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347954567-1.jpg",
                "_id": {
                    "$oid": "66f53cf2c4cb3eb7481fdb19"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347954567-2.jpg",
                "_id": {
                    "$oid": "66f53cf2c4cb3eb7481fdb1a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347954567-3.jpg",
                "_id": {
                    "$oid": "66f53cf2c4cb3eb7481fdb1b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727347954567-4.jpg",
                "_id": {
                    "$oid": "66f53cf2c4cb3eb7481fdb1c"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 729,
        "c_description": "Design Details\nPurple and blue paithani saree\nWoven design saree with woven design border \nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Normal",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:52:34.817Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:52:34.817Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53d38c4cb3eb7481fdb1f"
        },
        "c_name": "Floral Gotapatti Embroidered Tissue Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348024121-1.jpg",
                "_id": {
                    "$oid": "66f53d38c4cb3eb7481fdb20"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348024121-2.jpg",
                "_id": {
                    "$oid": "66f53d38c4cb3eb7481fdb21"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348024121-3.jpg",
                "_id": {
                    "$oid": "66f53d38c4cb3eb7481fdb22"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348024121-4.jpg",
                "_id": {
                    "$oid": "66f53d38c4cb3eb7481fdb23"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 4792,
        "c_description": "Design Details\nGreen and gold-toned saree\nFloral embroidered saree with embroidered border \nHas embroidered detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Normal",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:53:44.415Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:53:44.415Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53d51c4cb3eb7481fdb26"
        },
        "c_name": "Printed Ethnic Motifs Ikat Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348048964-1.jpg",
                "_id": {
                    "$oid": "66f53d51c4cb3eb7481fdb27"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348048964-2.jpg",
                "_id": {
                    "$oid": "66f53d51c4cb3eb7481fdb28"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348048964-3.jpg",
                "_id": {
                    "$oid": "66f53d51c4cb3eb7481fdb29"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348048964-4.jpg",
                "_id": {
                    "$oid": "66f53d51c4cb3eb7481fdb2a"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 899,
        "c_description": "Design Details\nBlack and beige ikat saree\nEthnic motifs printed saree with solid border \n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Normal",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:54:09.210Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:54:09.210Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f53e44c4cb3eb7481fdb51"
        },
        "c_name": "Purple & Gold-Toned Floral Woven Design Zari Banarasi Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348292318-1.jpg",
                "_id": {
                    "$oid": "66f53e44c4cb3eb7481fdb52"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348292318-2.jpg",
                "_id": {
                    "$oid": "66f53e44c4cb3eb7481fdb53"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348292318-3.jpg",
                "_id": {
                    "$oid": "66f53e44c4cb3eb7481fdb54"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727348292318-4.jpg",
                "_id": {
                    "$oid": "66f53e44c4cb3eb7481fdb55"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1099,
        "c_description": "Design Details\nPurple and gold-toned banarasi saree\nFloral woven design saree with woven design border \nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-26T10:58:12.528Z"
        },
        "updatedAt": {
            "$date": "2024-09-26T10:58:12.528Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66f7e041ae5c4ec89551e796"
        },
        "c_name": "Black & Gold-Toned Woven Design Zari Silk Blend Banarasi Sarees",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727520832702-1.jpg",
                "_id": {
                    "$oid": "66f7e041ae5c4ec89551e797"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727520832702-2.jpg",
                "_id": {
                    "$oid": "66f7e041ae5c4ec89551e798"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727520832702-3.jpg",
                "_id": {
                    "$oid": "66f7e041ae5c4ec89551e799"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1727520832702-4.jpg",
                "_id": {
                    "$oid": "66f7e041ae5c4ec89551e79a"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 879,
        "c_description": "Design Details\nBlack and gold-toned banarasi saree\nWoven design saree with woven design border\nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-09-28T10:53:53.070Z"
        },
        "updatedAt": {
            "$date": "2024-09-28T10:53:53.070Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd458584a4f778f5ae9ce"
        },
        "c_name": "Printed Ready To Wear Lehenga & Blouse With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042071801-1.jpg",
                "_id": {
                    "$oid": "66ffd458584a4f778f5ae9cf"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042071801-2.jpg",
                "_id": {
                    "$oid": "66ffd458584a4f778f5ae9d0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042071801-3.jpg",
                "_id": {
                    "$oid": "66ffd458584a4f778f5ae9d1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042071801-4.jpg",
                "_id": {
                    "$oid": "66ffd458584a4f778f5ae9d2"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 1575,
        "c_description": "Red printed lehenga choli with dupatta,\nRed printed ready to wear blouse, has a v-neck, sleeveless, slip-on closure\nRed printed ready to wear lehenga, has slip-on closure, flared hem, \nRed embellished dupatta, taping border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:41:12.096Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:41:12.096Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd4ae584a4f778f5ae9dd"
        },
        "c_name": "Pink Embroidered Sequinned Semi-Stitched Lehenga & Unstitched Blouse With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042157624-1.jpg",
                "_id": {
                    "$oid": "66ffd4ae584a4f778f5ae9de"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042157624-2.jpg",
                "_id": {
                    "$oid": "66ffd4ae584a4f778f5ae9df"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042157624-3.jpg",
                "_id": {
                    "$oid": "66ffd4ae584a4f778f5ae9e0"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 12739,
        "c_description": "Pink embroidered lehenga choli with dupatta, sequinned\nPink embroidered unstitched blouse, has a v-neck, sleeveless,\nPink embroidered semi-stitched lehenga, flared hem\nPink embellished dupatta, taping border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:42:38.022Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:42:38.022Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd4ca584a4f778f5ae9e3"
        },
        "c_name": "Olive Green & Silver-Toned Printed Ready to Wear Lehenga & Blouse With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042185920-1.jpg",
                "_id": {
                    "$oid": "66ffd4ca584a4f778f5ae9e4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042185920-2.jpg",
                "_id": {
                    "$oid": "66ffd4ca584a4f778f5ae9e5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042185920-3.jpg",
                "_id": {
                    "$oid": "66ffd4ca584a4f778f5ae9e6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042185920-4.jpg",
                "_id": {
                    "$oid": "66ffd4ca584a4f778f5ae9e7"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 2356,
        "c_description": "Olive green and silver-toned printed lehenga choli with dupatta, gotta patti\nOlive green and silver-toned printed ready to wear blouse, has a u-neck, sleeveless, zip closure\nOlive green and silver-toned woven design ready to wear lehenga, has slip-on closure, flared hem\nOlive green and silver-toned printed dupatta, border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:43:06.265Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:43:06.265Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd4f1584a4f778f5ae9ef"
        },
        "c_name": "Embroidered Sequinned Ready to Wear Lehenga & Choli",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042225380-1.jpg",
                "_id": {
                    "$oid": "66ffd4f1584a4f778f5ae9f0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042225380-2.jpg",
                "_id": {
                    "$oid": "66ffd4f1584a4f778f5ae9f1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042225380-3.jpg",
                "_id": {
                    "$oid": "66ffd4f1584a4f778f5ae9f2"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 1664,
        "c_description": "Black embroidered lehenga choli, sequinned\nBlack embroidered ready to wear blouse, has a v-neck, three-quarter sleeves,\nBlack solid ready to wear lehenga, has slip-on closure, flared hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:43:45.571Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:43:45.571Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd509584a4f778f5ae9f5"
        },
        "c_name": "Yellow & Pink Printed Ready to Wear Lehenga & Choli",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042248993-1.jpg",
                "_id": {
                    "$oid": "66ffd509584a4f778f5ae9f6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042248993-2.jpg",
                "_id": {
                    "$oid": "66ffd509584a4f778f5ae9f7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042248993-3.jpg",
                "_id": {
                    "$oid": "66ffd509584a4f778f5ae9f8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042248993-4.jpg",
                "_id": {
                    "$oid": "66ffd509584a4f778f5ae9f9"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 1439,
        "c_description": "Yellow and pink printed lehenga choli,\nYellow and pink printed ready to wear blouse, has a v-neck, sleeveless, zip closure\nYellow and pink printed ready to wear lehenga, has zip closure, flared hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:44:09.186Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:44:09.186Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd59d584a4f778f5aea33"
        },
        "c_name": "Embroidered Sequinned Semi-Stitched Lehenga & Unstitched Blouse With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042397511-1.jpg",
                "_id": {
                    "$oid": "66ffd59d584a4f778f5aea34"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042397511-2.jpg",
                "_id": {
                    "$oid": "66ffd59d584a4f778f5aea35"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042397511-3.jpg",
                "_id": {
                    "$oid": "66ffd59d584a4f778f5aea36"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042397511-4.jpg",
                "_id": {
                    "$oid": "66ffd59d584a4f778f5aea37"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 7594,
        "c_description": "Cream-coloured and blue embroidered lehenga choli with dupatta, sequinned\nCream-coloured and blue embroidered unstitched blouse, has a round neck, three-quarter sleeves, cream-coloured and blue embroidered semi-stitched lehenga, flared hem\nCream-coloured and blue embellished dupatta, taping border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:46:37.686Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:46:37.686Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd5b0584a4f778f5aea3a"
        },
        "c_name": "Printed Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042416588-1.jpg",
                "_id": {
                    "$oid": "66ffd5b0584a4f778f5aea3b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042416588-2.jpg",
                "_id": {
                    "$oid": "66ffd5b0584a4f778f5aea3c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042416588-3.jpg",
                "_id": {
                    "$oid": "66ffd5b0584a4f778f5aea3d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042416588-4.jpg",
                "_id": {
                    "$oid": "66ffd5b0584a4f778f5aea3e"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 799,
        "c_description": "Beige and black printed basic jumpsuit with waist tie-ups ,has a round neck, sleeveless, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:46:56.841Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:46:56.841Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd5b2584a4f778f5aea41"
        },
        "c_name": "Beige & Pink Printed Ready to Wear Lehenga",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042418166-1.jpg",
                "_id": {
                    "$oid": "66ffd5b2584a4f778f5aea42"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042418166-2.jpg",
                "_id": {
                    "$oid": "66ffd5b2584a4f778f5aea43"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042418166-3.jpg",
                "_id": {
                    "$oid": "66ffd5b2584a4f778f5aea44"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042418166-4.jpg",
                "_id": {
                    "$oid": "66ffd5b2584a4f778f5aea45"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 1199,
        "c_description": "Beige and pink printed lehenga choli,\nBeige and pink printed ready to wear blouse, has a v-neck, sleeveless, zip closure\nBeige and pink printed ready to wear lehenga, has slip-on closure, flared hem\n",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:46:58.386Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:46:58.386Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd5db584a4f778f5aea4c"
        },
        "c_name": "Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042458873-1.jpg",
                "_id": {
                    "$oid": "66ffd5db584a4f778f5aea4d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042458873-2.jpg",
                "_id": {
                    "$oid": "66ffd5db584a4f778f5aea4e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042458873-3.jpg",
                "_id": {
                    "$oid": "66ffd5db584a4f778f5aea4f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042458873-4.jpg",
                "_id": {
                    "$oid": "66ffd5db584a4f778f5aea50"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 359,
        "c_description": "Black solid basic jumpsuit ,has a shoulder straps, sleeveless, na pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:47:39.339Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:47:39.339Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd5e6584a4f778f5aea53"
        },
        "c_name": "Printed Semi-Stitched Lehenga & Unstitched Blouse With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042470023-1.jpg",
                "_id": {
                    "$oid": "66ffd5e6584a4f778f5aea54"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042470023-2.jpg",
                "_id": {
                    "$oid": "66ffd5e6584a4f778f5aea55"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042470023-3.jpg",
                "_id": {
                    "$oid": "66ffd5e6584a4f778f5aea56"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042470023-4.jpg",
                "_id": {
                    "$oid": "66ffd5e6584a4f778f5aea57"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 2199,
        "c_description": "Black and white printed lehenga choli with dupatta, gotta patti\nBlack and white printed unstitched blouse, has a v-neck, short sleeves, black and white printed semi-stitched lehenga, flared hem\nBlack and white printed dupatta, taping border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:47:50.208Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:47:50.208Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd5f3584a4f778f5aea5a"
        },
        "c_name": "Women Pretty Pink Solid Lacework and Sheer Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042483348-1.jpg",
                "_id": {
                    "$oid": "66ffd5f3584a4f778f5aea5b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042483348-2.jpg",
                "_id": {
                    "$oid": "66ffd5f3584a4f778f5aea5c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042483348-3.jpg",
                "_id": {
                    "$oid": "66ffd5f3584a4f778f5aea5d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042483348-4.jpg",
                "_id": {
                    "$oid": "66ffd5f3584a4f778f5aea5e"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1187,
        "c_description": "Fulfill your summer fantasy with this chic jumpsuit. This jumpsuit is designed with an eye-catching round neck and short sleeves to enhance your look. \nPretty pink shade\nClassic solid pattern\nRound neck\nShort sleeves\nPolyester, hand wash\nTrend Alert\nLacework adds a delicate, feminine touch to any garment and looks immaculate when blended with sheer fabrics. Lacework includes dainty embroidery work adorned with floral or geometric designs, fine floral motifs outlined with silk threads, raised motifs with beads, and more.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:48:03.609Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:48:03.609Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd602584a4f778f5aea61"
        },
        "c_name": "Printed V-Neck Ready to Wear Lehenga & Blouse With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042498066-1.jpg",
                "_id": {
                    "$oid": "66ffd602584a4f778f5aea62"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042498066-2.jpg",
                "_id": {
                    "$oid": "66ffd602584a4f778f5aea63"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042498066-3.jpg",
                "_id": {
                    "$oid": "66ffd602584a4f778f5aea64"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042498066-4.jpg",
                "_id": {
                    "$oid": "66ffd602584a4f778f5aea65"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 1540,
        "c_description": "Green and beige printed lehenga choli with dupatta,\nGreen and beige printed ready to wear blouse, has a round neck, short sleeves, slip-on closure\nGreen and beige printed ready to wear lehenga, has slip-on closure, flared hem\nGreen and beige solid dupatta, taping border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:48:18.333Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:48:18.333Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd60f584a4f778f5aea68"
        },
        "c_name": "Floral Printed V-Neck Flared Sleeves Playsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042510968-1.jpg",
                "_id": {
                    "$oid": "66ffd60f584a4f778f5aea69"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042510968-2.jpg",
                "_id": {
                    "$oid": "66ffd60f584a4f778f5aea6a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042510968-3.jpg",
                "_id": {
                    "$oid": "66ffd60f584a4f778f5aea6b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042510968-4.jpg",
                "_id": {
                    "$oid": "66ffd60f584a4f778f5aea6c"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 507,
        "c_description": "Blue and red printed playsuit with waist tie-ups ,has a v-neck, short sleeves",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:48:31.292Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:48:31.292Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd611584a4f778f5aea6f"
        },
        "c_name": "Multicoloured Embroidered Thread Work Semi-Stitched Lehenga & Unstitched Blouse With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042513292-1.jpg",
                "_id": {
                    "$oid": "66ffd611584a4f778f5aea70"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042513292-2.jpg",
                "_id": {
                    "$oid": "66ffd611584a4f778f5aea71"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042513292-3.jpg",
                "_id": {
                    "$oid": "66ffd611584a4f778f5aea72"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042513292-4.jpg",
                "_id": {
                    "$oid": "66ffd611584a4f778f5aea73"
                }
            }
        ],
        "c_brand": "LEHENGA CHOLI",
        "c_price": 3385,
        "c_description": "Multicoloured embroidered lehenga choli with dupatta, thread work\nMulticoloured embroidered unstitched blouse, short sleeves, multicoloured and embroidered semi-stitched lehenga, flared hem\nPink net dupatta, tassels border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:48:33.485Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:48:33.485Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd628584a4f778f5aea79"
        },
        "c_name": "Strapless Cinched Waist Playsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042536510-1.jpg",
                "_id": {
                    "$oid": "66ffd628584a4f778f5aea7a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042536510-2.jpg",
                "_id": {
                    "$oid": "66ffd628584a4f778f5aea7b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042536510-3.jpg",
                "_id": {
                    "$oid": "66ffd628584a4f778f5aea7c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042536510-4.jpg",
                "_id": {
                    "$oid": "66ffd628584a4f778f5aea7d"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 999,
        "c_description": "Black solid playsuit ,has a strapless, sleeveless",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:48:56.878Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:48:56.878Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd647584a4f778f5aea80"
        },
        "c_name": "Blue & White Linen Printed Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042567057-1.jpg",
                "_id": {
                    "$oid": "66ffd647584a4f778f5aea81"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042567057-2.jpg",
                "_id": {
                    "$oid": "66ffd647584a4f778f5aea82"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042567057-3.jpg",
                "_id": {
                    "$oid": "66ffd647584a4f778f5aea83"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042567057-4.jpg",
                "_id": {
                    "$oid": "66ffd647584a4f778f5aea84"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 935,
        "c_description": "Blue and white printed playsuit ,has a shirt collar, short sleeves",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:49:27.179Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:49:27.179Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd65e584a4f778f5aea87"
        },
        "c_name": "Women Peg Leg Loose Fit High-Rise Wrinkle Free Parallel Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042589879-1.jpg",
                "_id": {
                    "$oid": "66ffd65e584a4f778f5aea88"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042589879-2.jpg",
                "_id": {
                    "$oid": "66ffd65e584a4f778f5aea89"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042589879-3.jpg",
                "_id": {
                    "$oid": "66ffd65e584a4f778f5aea8a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042589879-4.jpg",
                "_id": {
                    "$oid": "66ffd65e584a4f778f5aea8b"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 1199,
        "c_description": "Black knitted parallel trousers\nLoose fit\nBrand Fit: peg leg\nHigh-rise\nLength: regular\nPattern: solid\nFlat-front, with no pleats design\nFeature: wrinkle free\n2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:49:50.026Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:49:50.026Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd660584a4f778f5aea8e"
        },
        "c_name": "Printed Sleeveless Pure Cotton Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042592500-1.jpg",
                "_id": {
                    "$oid": "66ffd660584a4f778f5aea8f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042592500-2.jpg",
                "_id": {
                    "$oid": "66ffd660584a4f778f5aea90"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042592500-3.jpg",
                "_id": {
                    "$oid": "66ffd660584a4f778f5aea91"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 769,
        "c_description": "Red and white printed basic jumpsuit ,has a round neck, sleeveless, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:49:52.635Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:49:52.635Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd673584a4f778f5aea94"
        },
        "c_name": "Off-White Embroidered Top",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042611162-1.jpg",
                "_id": {
                    "$oid": "66ffd673584a4f778f5aea95"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042611162-2.jpg",
                "_id": {
                    "$oid": "66ffd673584a4f778f5aea96"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042611162-3.jpg",
                "_id": {
                    "$oid": "66ffd673584a4f778f5aea97"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042611162-4.jpg",
                "_id": {
                    "$oid": "66ffd673584a4f778f5aea98"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 283,
        "c_description": "Off-White embroidered woven regular top, has a V-neck, short sleeves, and button closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:50:11.399Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:50:11.399Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd679584a4f778f5aea9b"
        },
        "c_name": "Waist Tie-Ups Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042617430-1.jpg",
                "_id": {
                    "$oid": "66ffd679584a4f778f5aea9c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042617430-2.jpg",
                "_id": {
                    "$oid": "66ffd679584a4f778f5aea9d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042617430-3.jpg",
                "_id": {
                    "$oid": "66ffd679584a4f778f5aea9e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042617430-4.jpg",
                "_id": {
                    "$oid": "66ffd679584a4f778f5aea9f"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1199,
        "c_description": "Lavender solid basic jumpsuit,,has a v-neck, sleeveless, has waist tie-ups detail",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:50:17.683Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:50:17.683Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd68f584a4f778f5aeaa2"
        },
        "c_name": "V-Neck Sleeveless Slim-Fit Waistcoat",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042639420-1.jpg",
                "_id": {
                    "$oid": "66ffd68f584a4f778f5aeaa3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042639420-2.jpg",
                "_id": {
                    "$oid": "66ffd68f584a4f778f5aeaa4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042639420-3.jpg",
                "_id": {
                    "$oid": "66ffd68f584a4f778f5aeaa5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042639420-4.jpg",
                "_id": {
                    "$oid": "66ffd68f584a4f778f5aeaa6"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 1595,
        "c_description": "Black solid slim-fit waistcoat, has a v-neck, a full button placket, sleeveless and two pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:50:39.755Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:50:39.755Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd6a2584a4f778f5aeaa9"
        },
        "c_name": "Women Slim-Fit Ankle-Length Sports Tights",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042658040-1.jpg",
                "_id": {
                    "$oid": "66ffd6a2584a4f778f5aeaaa"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042658040-2.jpg",
                "_id": {
                    "$oid": "66ffd6a2584a4f778f5aeaab"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042658040-3.jpg",
                "_id": {
                    "$oid": "66ffd6a2584a4f778f5aeaac"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042658040-4.jpg",
                "_id": {
                    "$oid": "66ffd6a2584a4f778f5aeaad"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 959,
        "c_description": "Black solid ankle-length high-rise training tights has an elasticated waistband with slip-on closure and hidden back pocket\n\nFeatures:\nAnkle length\nSeamless, no-chafing design\nElasticated waistband\nLightweight, soft and stretchy",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:50:58.293Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:50:58.293Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd6b2584a4f778f5aeab0"
        },
        "c_name": "Round Neck Three-Quarter Sleeves Top",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042674511-1.jpg",
                "_id": {
                    "$oid": "66ffd6b2584a4f778f5aeab1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042674511-2.jpg",
                "_id": {
                    "$oid": "66ffd6b2584a4f778f5aeab2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042674511-3.jpg",
                "_id": {
                    "$oid": "66ffd6b2584a4f778f5aeab3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042674511-4.jpg",
                "_id": {
                    "$oid": "66ffd6b2584a4f778f5aeab4"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 464,
        "c_description": "Magenta regular regular top\nSolid\nRound neck, three-quarter, regular sleeves\nWoven crepe fabric",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:51:14.804Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:51:14.804Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd6c0584a4f778f5aeab7"
        },
        "c_name": "Women Classic White Solid Cropped Top",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042688618-1.jpg",
                "_id": {
                    "$oid": "66ffd6c0584a4f778f5aeab8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042688618-2.jpg",
                "_id": {
                    "$oid": "66ffd6c0584a4f778f5aeab9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042688618-3.jpg",
                "_id": {
                    "$oid": "66ffd6c0584a4f778f5aeaba"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042688618-4.jpg",
                "_id": {
                    "$oid": "66ffd6c0584a4f778f5aeabb"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 263,
        "c_description": "Own the party in this stylishly designed top. Designed with a beautiful solid pattern and shirt collar, this top makes you look distinct. \nWhite shade\nClassic solid pattern\nShirt collar\nShort regular sleeves\nPolyester, machine wash\nTrend Alert\nCropped length refers to any item that is shorter than usual. This style often lends a contemporary and distinct look.",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:51:28.933Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:51:28.933Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd711becb4652de3946c6"
        },
        "c_name": "Women Slim Fit High-Rise Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042769388-1.jpg",
                "_id": {
                    "$oid": "66ffd711becb4652de3946c7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042769388-2.jpg",
                "_id": {
                    "$oid": "66ffd711becb4652de3946c8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042769388-3.jpg",
                "_id": {
                    "$oid": "66ffd711becb4652de3946c9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042769388-4.jpg",
                "_id": {
                    "$oid": "66ffd711becb4652de3946ca"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 3419,
        "c_description": "Brown woven trousers\nSlim fit\nHigh-rise\nLength: regular\nPattern: solid\nFlat-front \nFeature: plain\n3 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:52:49.786Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:52:49.786Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd71fbecb4652de3946cd"
        },
        "c_name": "Women Colourblocked Tracksuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042783397-1.jpg",
                "_id": {
                    "$oid": "66ffd71fbecb4652de3946ce"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042783397-2.jpg",
                "_id": {
                    "$oid": "66ffd71fbecb4652de3946cf"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042783397-3.jpg",
                "_id": {
                    "$oid": "66ffd71fbecb4652de3946d0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042783397-4.jpg",
                "_id": {
                    "$oid": "66ffd71fbecb4652de3946d1"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 1987,
        "c_description": "Navy blue, grey and blue colourblocked tracksuit\nNavy blue, grey and blue colourblocked sweat jacket, has mock collar, long sleeves with ribbed cuffs, zip closure and two pockets\nNavy blue solid mid-rise track pants, has an elasticated waistband with drawstring closure and two pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:53:03.567Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:53:03.567Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd72fbecb4652de3946d4"
        },
        "c_name": "Women Multicoloured Printed Tie-Up Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042798925-1.jpg",
                "_id": {
                    "$oid": "66ffd72fbecb4652de3946d5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042798925-2.jpg",
                "_id": {
                    "$oid": "66ffd72fbecb4652de3946d6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042798925-3.jpg",
                "_id": {
                    "$oid": "66ffd72fbecb4652de3946d7"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 657,
        "c_description": "Brown and blue printed tie-up longline shrug, has three-quarter sleeves, gathers detail, straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:53:19.167Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:53:19.167Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd740becb4652de3946da"
        },
        "c_name": "Women Printed Boat Neck Sheath Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042815896-1.jpg",
                "_id": {
                    "$oid": "66ffd740becb4652de3946db"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042815896-2.jpg",
                "_id": {
                    "$oid": "66ffd740becb4652de3946dc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042815896-3.jpg",
                "_id": {
                    "$oid": "66ffd740becb4652de3946dd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042815896-4.jpg",
                "_id": {
                    "$oid": "66ffd740becb4652de3946de"
                }
            }
        ],
        "c_brand": "WESTERN WEAR",
        "c_price": 2846,
        "c_description": "Off white geometric printed sheath dress\nBoat neck\nThree-quarter, regular sleeves\nAbove knee length in straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:53:36.077Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:53:36.077Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd772becb4652de3946f4"
        },
        "c_name": "Mustard Yellow Ethnic Motifs Printed Round Neck Sequinned Straight Kurta Set",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042866040-1.jpg",
                "_id": {
                    "$oid": "66ffd772becb4652de3946f5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042866040-2.jpg",
                "_id": {
                    "$oid": "66ffd772becb4652de3946f6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042866040-3.jpg",
                "_id": {
                    "$oid": "66ffd772becb4652de3946f7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042866040-4.jpg",
                "_id": {
                    "$oid": "66ffd772becb4652de3946f8"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 1899,
        "c_description": "Mustard yellow printed Kurta with Trousers with dupatta\n\nKurta design:\nEthnic motifs printed\nStraight shape\nRegular style\nRound neck, three-quarter flared sleeves\n2 pockets sequinned detail\nCalf length length with straight hem\nSilk blend machine weave fabric\n\nTrousers design:\nSolid Trousers\nPartially elasticated waistband\nSlip-on closure\nDupatta design:\nPrinted\nSilk Blend",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:54:26.292Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:54:26.292Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd781becb4652de3946fb"
        },
        "c_name": "Floral Printed Mandarin Collar Pure Cotton Pleated A-Line Tunic",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042880780-1.jpg",
                "_id": {
                    "$oid": "66ffd781becb4652de3946fc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042880780-2.jpg",
                "_id": {
                    "$oid": "66ffd781becb4652de3946fd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042880780-3.jpg",
                "_id": {
                    "$oid": "66ffd781becb4652de3946fe"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042880780-4.jpg",
                "_id": {
                    "$oid": "66ffd781becb4652de3946ff"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 692,
        "c_description": "Colour: off white, blue and green \nFloral printed\nMandarin collar \nThree-quarter regular sleeves\nA-line shape with regular style \nAbove knee length with flared hem ",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:54:41.042Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:54:41.042Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd79bbecb4652de394702"
        },
        "c_name": "Floral Printed Straight Kurta with Trousers & Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042906547-1.jpg",
                "_id": {
                    "$oid": "66ffd79bbecb4652de394703"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042906547-2.jpg",
                "_id": {
                    "$oid": "66ffd79bbecb4652de394704"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042906547-3.jpg",
                "_id": {
                    "$oid": "66ffd79bbecb4652de394705"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042906547-4.jpg",
                "_id": {
                    "$oid": "66ffd79bbecb4652de394706"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 899,
        "c_description": "Peach-coloured, green and yellow printed Kurta with Trousers with dupatta\n\nKurta design:\nFloral printed\nStraight shape\nRegular style\nV-neck, three-quarter regular sleeves\nCalf length with straight hem\nSilk blend machine weave fabric\n\nTrousers design:\nSolid Trousers\nPartially elasticated waistband\nDrawstring closure\nDupatta design:\nPrinted dupatta\nPrinted border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:55:07.082Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:55:07.082Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd7aabecb4652de394709"
        },
        "c_name": "Ethnic Motifs Printed Art Silk Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042922181-1.jpg",
                "_id": {
                    "$oid": "66ffd7aabecb4652de39470a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042922181-2.jpg",
                "_id": {
                    "$oid": "66ffd7aabecb4652de39470b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042922181-3.jpg",
                "_id": {
                    "$oid": "66ffd7aabecb4652de39470c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042922181-4.jpg",
                "_id": {
                    "$oid": "66ffd7aabecb4652de39470d"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 499,
        "c_description": "Black and orange ethnic motifs printed dupatta, tasselled border",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:55:22.585Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:55:22.585Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd7babecb4652de394710"
        },
        "c_name": "Bandhani Embroidered Regular Sequinned Kurta With Trouser & Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042938242-1.jpg",
                "_id": {
                    "$oid": "66ffd7babecb4652de394711"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042938242-2.jpg",
                "_id": {
                    "$oid": "66ffd7babecb4652de394712"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042938242-3.jpg",
                "_id": {
                    "$oid": "66ffd7babecb4652de394713"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042938242-4.jpg",
                "_id": {
                    "$oid": "66ffd7babecb4652de394714"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 1398,
        "c_description": "Yellow embroidered Kurta with Trouser & dupatta\n\nKurta design:\nBandhani embroidered\nStraight shape\nRegular style\nV-neck, three-quarter regular sleeves\nSequinned detail\nCalf length with straight hem\nArt silk machine weave fabric\n\nTrousers design:\nEmbroidered Trousers\nElasticated waistband\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:55:38.593Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:55:38.593Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd7c8becb4652de394719"
        },
        "c_name": "Patola Printed Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042952753-1.jpg",
                "_id": {
                    "$oid": "66ffd7c8becb4652de39471a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042952753-2.jpg",
                "_id": {
                    "$oid": "66ffd7c8becb4652de39471b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042952753-3.jpg",
                "_id": {
                    "$oid": "66ffd7c8becb4652de39471c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042952753-4.jpg",
                "_id": {
                    "$oid": "66ffd7c8becb4652de39471d"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 545,
        "c_description": "Black & green Patola Printed dupatta has tasseled Lace border.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:55:52.912Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:55:52.912Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd7d9becb4652de394720"
        },
        "c_name": "Ethnic Motifs Yoke Design Embroidered Kaftan Kurta & Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042968773-1.jpg",
                "_id": {
                    "$oid": "66ffd7d9becb4652de394721"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042968773-2.jpg",
                "_id": {
                    "$oid": "66ffd7d9becb4652de394722"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042968773-3.jpg",
                "_id": {
                    "$oid": "66ffd7d9becb4652de394723"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728042968773-4.jpg",
                "_id": {
                    "$oid": "66ffd7d9becb4652de394724"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 1363,
        "c_description": "This kurta set consists of kurta & trouser\n\nKurta Design:\nPurple & gold toned Kurta\nEmbroidered details\nV-Neck\nThree quarter sleeves\nKaftan shaped\nAbove knee-length with flared hem and side slits\nMachine weave regular shantoon\n\nTrouser Design:\nPurple solid trouser\nElasticated Waistband\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:56:09.209Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:56:09.209Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd809becb4652de394728"
        },
        "c_name": "Floral Embroidered Pleated Thread Work Georgette A-Line Kurti With Sharara",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043017086-1.jpg",
                "_id": {
                    "$oid": "66ffd809becb4652de394729"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043017086-2.jpg",
                "_id": {
                    "$oid": "66ffd809becb4652de39472a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043017086-3.jpg",
                "_id": {
                    "$oid": "66ffd809becb4652de39472b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043017086-4.jpg",
                "_id": {
                    "$oid": "66ffd809becb4652de39472c"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 3585,
        "c_description": "Black, green & red embroidered Kurta with Sharara\n\nKurta design:\nFloral embroidered\nA-line shape\nPleated style\nRound neck, long regular sleeves\nThread work detail\nAbove knee length with flared hem\nGeorgette machine weave fabric\n\nSharara design:\nSolid Sharara\nPartially elasticated waistband\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:56:57.270Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:56:57.270Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd81abecb4652de39472f"
        },
        "c_name": "Floral Printed Anarkali Pure Cotton Kurta with Trousers & Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043034452-1.jpg",
                "_id": {
                    "$oid": "66ffd81abecb4652de394730"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043034452-2.jpg",
                "_id": {
                    "$oid": "66ffd81abecb4652de394731"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043034452-3.jpg",
                "_id": {
                    "$oid": "66ffd81abecb4652de394732"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043034452-4.jpg",
                "_id": {
                    "$oid": "66ffd81abecb4652de394733"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 1047,
        "c_description": "Blue & white printed Kurta with Trousers with dupatta\n\nKurta design:\nFloral printed\nAnarkali shape\nRegularstyle\nRound neck, three-quarter regular sleeves\nCalf length length with flared hem\nPure cotton machine weave fabric\n\nTrousers design:\nPrinted Trousers\nElasticated waistband\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:57:14.894Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:57:14.894Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffd82dbecb4652de394736"
        },
        "c_name": "Ethnic Motifs Printed Mirror Work Anarkali Kurta with Trousers & Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043052828-1.jpg",
                "_id": {
                    "$oid": "66ffd82dbecb4652de394737"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043052828-2.jpg",
                "_id": {
                    "$oid": "66ffd82dbecb4652de394738"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043052828-3.jpg",
                "_id": {
                    "$oid": "66ffd82dbecb4652de394739"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043052828-4.jpg",
                "_id": {
                    "$oid": "66ffd82dbecb4652de39473a"
                }
            }
        ],
        "c_brand": "ETHNIC WEAR",
        "c_price": 779,
        "c_description": "Blue and white kurta with trousers & dupatta\n\nKurta design:\nFloral printed\nAnarkali shape\nPaneled style\nRound neck, three-quarter regular sleeves\nMirror work\nCalf length with flared hem\nViscose rayon machine weave fabric\n\nTrousers design:\nPrinted trousers\nElasticated waistband",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T11:57:33.323Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T11:57:33.323Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffda9a584a4f778f5aeb11"
        },
        "c_name": "Floral Print V-Neck Georgette Maxi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043674104-1.jpg",
                "_id": {
                    "$oid": "66ffda9a584a4f778f5aeb12"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043674104-2.jpg",
                "_id": {
                    "$oid": "66ffda9a584a4f778f5aeb13"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043674104-3.jpg",
                "_id": {
                    "$oid": "66ffda9a584a4f778f5aeb14"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043674104-4.jpg",
                "_id": {
                    "$oid": "66ffda9a584a4f778f5aeb15"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 629,
        "c_description": "Navy blue and white floral print maxi dress\nV neck\nShort, cap sleeves\nEmbellished detail\nMaxi length in flared hem\nGeorgette fabric",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:07:54.200Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:07:54.200Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdaaa584a4f778f5aeb18"
        },
        "c_name": "Floral Printed Embroidered Puff Sleeve Chiffon Fit & Flare Tiered Midi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043690326-1.jpg",
                "_id": {
                    "$oid": "66ffdaaa584a4f778f5aeb19"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043690326-2.jpg",
                "_id": {
                    "$oid": "66ffdaaa584a4f778f5aeb1a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043690326-3.jpg",
                "_id": {
                    "$oid": "66ffdaaa584a4f778f5aeb1b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043690326-4.jpg",
                "_id": {
                    "$oid": "66ffdaaa584a4f778f5aeb1c"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1471,
        "c_description": "Blue floral print fit & flare dress\nV-neck\nThree-quarter, puff sleeve\nEmbroidered detail\nMidi length in flared hem\nChiffon fabric",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:08:10.457Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:08:10.457Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdabb584a4f778f5aeb1f"
        },
        "c_name": "Rose Dobby Woven Embellished Maxi Layered Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043706816-1.jpg",
                "_id": {
                    "$oid": "66ffdabb584a4f778f5aeb20"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043706816-2.jpg",
                "_id": {
                    "$oid": "66ffdabb584a4f778f5aeb21"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043706816-3.jpg",
                "_id": {
                    "$oid": "66ffdabb584a4f778f5aeb22"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043706816-4.jpg",
                "_id": {
                    "$oid": "66ffdabb584a4f778f5aeb23"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1286,
        "c_description": "Rose ethnic motifs embroidered dress\nMandarin collar\nLong, bishop sleeve\nEmbellished detail\nMidi length in flared hem\nDobby fabric",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:08:27.251Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:08:27.251Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdac9584a4f778f5aeb26"
        },
        "c_name": "Floral Printed Shoulder Straps Gathered & Tiered Detail A-Line Maxi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043721464-1.jpg",
                "_id": {
                    "$oid": "66ffdac9584a4f778f5aeb27"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043721464-2.jpg",
                "_id": {
                    "$oid": "66ffdac9584a4f778f5aeb28"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043721464-3.jpg",
                "_id": {
                    "$oid": "66ffdac9584a4f778f5aeb29"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043721464-4.jpg",
                "_id": {
                    "$oid": "66ffdac9584a4f778f5aeb2a"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 659,
        "c_description": "Multicolour floral print a-line dress\nShoulder straps\nSleeveless, no sleeves\nGathered or pleated detail\nMaxi length in flared hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:08:41.709Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:08:41.709Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdae5becb4652de39479f"
        },
        "c_name": "Women Floral Print Mandarin Collar Short Sleeves Fit and Flare Midi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043749132-1.jpg",
                "_id": {
                    "$oid": "66ffdae5becb4652de3947a0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043749132-2.jpg",
                "_id": {
                    "$oid": "66ffdae5becb4652de3947a1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043749132-3.jpg",
                "_id": {
                    "$oid": "66ffdae5becb4652de3947a2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043749132-4.jpg",
                "_id": {
                    "$oid": "66ffdae5becb4652de3947a3"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 985,
        "c_description": "Blue floral print fit and flared dress\nMandarin Collar\nShort, puff sleeves\nMidi length in flared hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:09:09.281Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:09:09.281Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdaf0becb4652de3947a6"
        },
        "c_name": "Teal Green & White Bandhani Printed Pure Cotton A-Line Midi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043760647-1.jpg",
                "_id": {
                    "$oid": "66ffdaf0becb4652de3947a7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043760647-2.jpg",
                "_id": {
                    "$oid": "66ffdaf0becb4652de3947a8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043760647-3.jpg",
                "_id": {
                    "$oid": "66ffdaf0becb4652de3947a9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043760647-4.jpg",
                "_id": {
                    "$oid": "66ffdaf0becb4652de3947aa"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1104,
        "c_description": "Teal green and white bandhani print a-line dress\nV-neck\nShort, puff sleeve\nGathered or pleated detail\nMidi length in flared hem\nCotton fabric",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:09:20.936Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:09:20.936Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb03becb4652de3947ad"
        },
        "c_name": "Floral Printed V-Neck Ruffled Tiered Fit & Flare Midi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043778908-1.jpg",
                "_id": {
                    "$oid": "66ffdb03becb4652de3947ae"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043778908-2.jpg",
                "_id": {
                    "$oid": "66ffdb03becb4652de3947af"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043778908-3.jpg",
                "_id": {
                    "$oid": "66ffdb03becb4652de3947b0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043778908-4.jpg",
                "_id": {
                    "$oid": "66ffdb03becb4652de3947b1"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 874,
        "c_description": "Pink and green floral printed fit & flare dress\nV-neck\nThree-quarter, regular sleeves\nRuffled detail\nMidi length in flared hem\nGeorgette fabric\nConcealed zip closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:09:39.091Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:09:39.091Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb10becb4652de3947b4"
        },
        "c_name": "Floral Printed Mandarin Collar Puff Sleeves Cotton Fit and Flare Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043791863-1.jpg",
                "_id": {
                    "$oid": "66ffdb10becb4652de3947b5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043791863-2.jpg",
                "_id": {
                    "$oid": "66ffdb10becb4652de3947b6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043791863-3.jpg",
                "_id": {
                    "$oid": "66ffdb10becb4652de3947b7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043791863-4.jpg",
                "_id": {
                    "$oid": "66ffdb10becb4652de3947b8"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 797,
        "c_description": "Blue floral print Fit and Flare dress\nMandarin collar\nShort, Puff Sleeves\nMidi length in flared hem\nCotton fabric",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:09:52.068Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:09:52.068Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb1bbecb4652de3947bb"
        },
        "c_name": "Floral Printed Roll-Up Sleeves Belted Pleated Fit & Flare Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043803823-1.jpg",
                "_id": {
                    "$oid": "66ffdb1bbecb4652de3947bc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043803823-2.jpg",
                "_id": {
                    "$oid": "66ffdb1bbecb4652de3947bd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043803823-3.jpg",
                "_id": {
                    "$oid": "66ffdb1bbecb4652de3947be"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043803823-4.jpg",
                "_id": {
                    "$oid": "66ffdb1bbecb4652de3947bf"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 890,
        "c_description": "Black & pink printed fit & flare dress\nMandarin collar\nMidi length \nShort, roll-up sleeves\nButton closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:10:03.976Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:10:03.976Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb27becb4652de3947c2"
        },
        "c_name": "Crepe A-Line Midi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043815121-1.jpg",
                "_id": {
                    "$oid": "66ffdb27becb4652de3947c3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043815121-2.jpg",
                "_id": {
                    "$oid": "66ffdb27becb4652de3947c4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043815121-3.jpg",
                "_id": {
                    "$oid": "66ffdb27becb4652de3947c5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043815121-4.jpg",
                "_id": {
                    "$oid": "66ffdb27becb4652de3947c6"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 649,
        "c_description": "Beige solid a-line dress\nShirt collar\nShort, regular sleeves\nBelted detail\nMidi length in flared hem\nCrepe fabric\nComes with a belt",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:10:15.438Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:10:15.438Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb53becb4652de3947c9"
        },
        "c_name": "Lime Green & Blue Satin Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043858988-1.jpg",
                "_id": {
                    "$oid": "66ffdb53becb4652de3947ca"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043858988-2.jpg",
                "_id": {
                    "$oid": "66ffdb53becb4652de3947cb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043858988-3.jpg",
                "_id": {
                    "$oid": "66ffdb53becb4652de3947cc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043858988-4.jpg",
                "_id": {
                    "$oid": "66ffdb53becb4652de3947cd"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 959,
        "c_description": "Design Details\nLime green and blue saree\nAbstract printed saree with solid border\nThe saree comes with an unstitched blouse piece, The blouse worn by the model might be for modelling purpose only",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:10:59.197Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:10:59.197Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb66becb4652de3947d0"
        },
        "c_name": "Bandhani Printed Gota Patti Traditional Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043878196-1.jpg",
                "_id": {
                    "$oid": "66ffdb66becb4652de3947d1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043878196-2.jpg",
                "_id": {
                    "$oid": "66ffdb66becb4652de3947d2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043878196-3.jpg",
                "_id": {
                    "$oid": "66ffdb66becb4652de3947d3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043878196-4.jpg",
                "_id": {
                    "$oid": "66ffdb66becb4652de3947d4"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 898,
        "c_description": "Design Details\nGreen and yellow saree With out Blouse\nBandhani printed saree with printed border ",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:11:18.682Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:11:18.682Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb76becb4652de3947d7"
        },
        "c_name": "Floral Printed Zari Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043894690-1.jpg",
                "_id": {
                    "$oid": "66ffdb76becb4652de3947d8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043894690-2.jpg",
                "_id": {
                    "$oid": "66ffdb76becb4652de3947d9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043894690-3.jpg",
                "_id": {
                    "$oid": "66ffdb76becb4652de3947da"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043894690-4.jpg",
                "_id": {
                    "$oid": "66ffdb76becb4652de3947db"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 636,
        "c_description": "Design Details\nLavender and white saree\nFloral printed saree with zari border \nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:11:34.869Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:11:34.869Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb84becb4652de3947de"
        },
        "c_name": "Silk Blend Kanjeevaram Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043908211-1.jpg",
                "_id": {
                    "$oid": "66ffdb84becb4652de3947df"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043908211-2.jpg",
                "_id": {
                    "$oid": "66ffdb84becb4652de3947e0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043908211-3.jpg",
                "_id": {
                    "$oid": "66ffdb84becb4652de3947e1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043908211-4.jpg",
                "_id": {
                    "$oid": "66ffdb84becb4652de3947e2"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1199,
        "c_description": "Design Details\nYellow kanjeevaram saree\nEthnic motifs woven design saree with woven design border \n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:11:48.498Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:11:48.498Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdb99becb4652de3947e5"
        },
        "c_name": "Yellow & Turquoise Blue Ethnic Motifs Zari Art Silk Banarasi Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043929528-1.jpg",
                "_id": {
                    "$oid": "66ffdb99becb4652de3947e6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043929528-2.jpg",
                "_id": {
                    "$oid": "66ffdb99becb4652de3947e7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043929528-3.jpg",
                "_id": {
                    "$oid": "66ffdb99becb4652de3947e8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043929528-4.jpg",
                "_id": {
                    "$oid": "66ffdb99becb4652de3947e9"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 799,
        "c_description": "Design Details\nYellow and turquoise blue banarasi saree\nEthnic motifs woven design saree with zari border\nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:12:09.740Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:12:09.740Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdba6becb4652de3947ec"
        },
        "c_name": "Banarasi Floral Print Cotton Blend Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043942503-1.jpg",
                "_id": {
                    "$oid": "66ffdba6becb4652de3947ed"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043942503-2.jpg",
                "_id": {
                    "$oid": "66ffdba6becb4652de3947ee"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043942503-3.jpg",
                "_id": {
                    "$oid": "66ffdba6becb4652de3947ef"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043942503-4.jpg",
                "_id": {
                    "$oid": "66ffdba6becb4652de3947f0"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 1049,
        "c_description": "Teal and pink colour saree\nFloral printed saree with woven design border\nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only.\nCheck the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:12:22.724Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:12:22.724Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdbb0becb4652de3947f3"
        },
        "c_name": "Cotton Blend Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043952390-1.jpg",
                "_id": {
                    "$oid": "66ffdbb0becb4652de3947f4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043952390-2.jpg",
                "_id": {
                    "$oid": "66ffdbb0becb4652de3947f5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043952390-3.jpg",
                "_id": {
                    "$oid": "66ffdbb0becb4652de3947f6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043952390-4.jpg",
                "_id": {
                    "$oid": "66ffdbb0becb4652de3947f7"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 2210,
        "c_description": "Design Details\nSolid saree with no border \nThis Charming Red Saree From Suta Is 5.5 Metres Long. The Saree Does Not Come With A Readymade Blouse As Donned By The Model In The Picture.\nDisclaimer: The Colour Of The Product Might Slightly Vary As The Sample Pictures Have Been Taken In Broad Daylight/Under Studio Lights.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:12:32.556Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:12:32.556Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdbbdbecb4652de3947fa"
        },
        "c_name": "Floral Zari Silk Blend Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043965263-1.jpg",
                "_id": {
                    "$oid": "66ffdbbdbecb4652de3947fb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043965263-2.jpg",
                "_id": {
                    "$oid": "66ffdbbdbecb4652de3947fc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043965263-3.jpg",
                "_id": {
                    "$oid": "66ffdbbdbecb4652de3947fd"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 774,
        "c_description": "Design Details\nMustard and green saree\nFloral woven design saree with no border border\nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:12:45.387Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:12:45.387Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdbc9becb4652de394800"
        },
        "c_name": "Ethnic Motif Woven Design Zari Silk Kanjeevaram Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043977258-1.jpg",
                "_id": {
                    "$oid": "66ffdbc9becb4652de394801"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043977258-2.jpg",
                "_id": {
                    "$oid": "66ffdbc9becb4652de394802"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043977258-3.jpg",
                "_id": {
                    "$oid": "66ffdbc9becb4652de394803"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043977258-4.jpg",
                "_id": {
                    "$oid": "66ffdbc9becb4652de394804"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 599,
        "c_description": "Design Details\nPurple and teal kanjeevaram saree\nEthnic motif woven design saree with woven design border \nHas zari detail\n\nThe saree comes with an unstitched blouse piece\nThe blouse worn by the model might be for modelling purpose only. Check the image of the blouse piece to understand how the actual blouse piece looks like.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:12:57.501Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:12:57.501Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdbd4becb4652de394807"
        },
        "c_name": "Blue & Pink Floral Saree",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043988761-1.jpg",
                "_id": {
                    "$oid": "66ffdbd4becb4652de394808"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043988761-2.jpg",
                "_id": {
                    "$oid": "66ffdbd4becb4652de394809"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043988761-3.jpg",
                "_id": {
                    "$oid": "66ffdbd4becb4652de39480a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728043988761-4.jpg",
                "_id": {
                    "$oid": "66ffdbd4becb4652de39480b"
                }
            }
        ],
        "c_brand": "SAREE",
        "c_price": 2475,
        "c_description": "This charming Multi color saree's drape is 5.5 meters long. The Saree does not come with a readymade blouse as donned by the model in the picture; But can be purchased separately from Suta.\nDisclaimer: The colour of the product might slightly vary as the sample pictures have been taken in broad daylight/under studio lights.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:13:08.995Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:13:08.995Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdc24584a4f778f5aeb64"
        },
        "c_name": "Women Ribbed Open Front Longline Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044068670-1.jpg",
                "_id": {
                    "$oid": "66ffdc24584a4f778f5aeb65"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044068670-2.jpg",
                "_id": {
                    "$oid": "66ffdc24584a4f778f5aeb66"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044068670-3.jpg",
                "_id": {
                    "$oid": "66ffdc24584a4f778f5aeb67"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044068670-4.jpg",
                "_id": {
                    "$oid": "66ffdc24584a4f778f5aeb68"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 664,
        "c_description": "Beige ribbed open front longline shrug ,has long sleeves, straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:14:28.817Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:14:28.817Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdc5a584a4f778f5aeb6c"
        },
        "c_name": "Tropical Printed Tasselled Longline Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044122410-1.jpg",
                "_id": {
                    "$oid": "66ffdc5a584a4f778f5aeb6d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044122410-2.jpg",
                "_id": {
                    "$oid": "66ffdc5a584a4f778f5aeb6e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044122410-3.jpg",
                "_id": {
                    "$oid": "66ffdc5a584a4f778f5aeb6f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044122410-4.jpg",
                "_id": {
                    "$oid": "66ffdc5a584a4f778f5aeb70"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 699,
        "c_description": "Blue & green tropical printed open front longline shrug, has three-quarter sleeves, tasselled detail, straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:15:22.919Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:15:22.919Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdc6c584a4f778f5aeb73"
        },
        "c_name": "Rust Floral Printed Kimono Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044140026-1.jpg",
                "_id": {
                    "$oid": "66ffdc6c584a4f778f5aeb74"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044140026-2.jpg",
                "_id": {
                    "$oid": "66ffdc6c584a4f778f5aeb75"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044140026-3.jpg",
                "_id": {
                    "$oid": "66ffdc6c584a4f778f5aeb76"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044140026-4.jpg",
                "_id": {
                    "$oid": "66ffdc6c584a4f778f5aeb77"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 629,
        "c_description": "Rust and white printed open front regular shrug ,has long sleeves, straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:15:40.289Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:15:40.289Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdc7a584a4f778f5aeb7a"
        },
        "c_name": "Women Longline Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044154574-1.jpg",
                "_id": {
                    "$oid": "66ffdc7a584a4f778f5aeb7b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044154574-2.jpg",
                "_id": {
                    "$oid": "66ffdc7a584a4f778f5aeb7c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044154574-3.jpg",
                "_id": {
                    "$oid": "66ffdc7a584a4f778f5aeb7d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044154574-4.jpg",
                "_id": {
                    "$oid": "66ffdc7a584a4f778f5aeb7e"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 1999,
        "c_description": "Brown solid open front longline shrug ,has long sleeves, straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:15:54.701Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:15:54.701Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdc8e584a4f778f5aeb81"
        },
        "c_name": "Tasselled Pure Cotton Open Front Crop Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044174509-1.jpg",
                "_id": {
                    "$oid": "66ffdc8e584a4f778f5aeb82"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044174509-2.jpg",
                "_id": {
                    "$oid": "66ffdc8e584a4f778f5aeb83"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044174509-3.jpg",
                "_id": {
                    "$oid": "66ffdc8e584a4f778f5aeb84"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044174509-4.jpg",
                "_id": {
                    "$oid": "66ffdc8e584a4f778f5aeb85"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 374,
        "c_description": "White self design open front crop shrug ,has short sleeves, tasselled detail, asymmetric hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:16:14.728Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:16:14.728Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdc99584a4f778f5aeb88"
        },
        "c_name": "Open Front Velvet Longline Shrug with Border",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044185200-1.jpg",
                "_id": {
                    "$oid": "66ffdc99584a4f778f5aeb89"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044185200-2.jpg",
                "_id": {
                    "$oid": "66ffdc99584a4f778f5aeb8a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044185200-3.jpg",
                "_id": {
                    "$oid": "66ffdc99584a4f778f5aeb8b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044185200-4.jpg",
                "_id": {
                    "$oid": "66ffdc99584a4f778f5aeb8c"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 988,
        "c_description": "Black solid velvet open front longline shrug with border ,has long sleeves, straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:16:25.479Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:16:25.479Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdcba584a4f778f5aeb8f"
        },
        "c_name": "Floral Printed Longline Tie-Up Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044218684-1.jpg",
                "_id": {
                    "$oid": "66ffdcba584a4f778f5aeb90"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044218684-2.jpg",
                "_id": {
                    "$oid": "66ffdcba584a4f778f5aeb91"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044218684-3.jpg",
                "_id": {
                    "$oid": "66ffdcba584a4f778f5aeb92"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044218684-4.jpg",
                "_id": {
                    "$oid": "66ffdcba584a4f778f5aeb93"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 769,
        "c_description": "Orange and multicoloured printed tie-up longline shrug ,has three-quarter sleeves, gathers detail, asymmetric hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:16:58.900Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:16:58.900Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdcc9584a4f778f5aeb96"
        },
        "c_name": "Women Checked Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044233284-1.jpg",
                "_id": {
                    "$oid": "66ffdcc9584a4f778f5aeb97"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044233284-2.jpg",
                "_id": {
                    "$oid": "66ffdcc9584a4f778f5aeb98"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044233284-3.jpg",
                "_id": {
                    "$oid": "66ffdcc9584a4f778f5aeb99"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044233284-4.jpg",
                "_id": {
                    "$oid": "66ffdcc9584a4f778f5aeb9a"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 4499,
        "c_description": "Brown and white checked open front regular shrug ,has three-quarter sleeves, asymmetric hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:17:13.539Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:17:13.539Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdcd4584a4f778f5aeb9d"
        },
        "c_name": "Women Black & White Printed Longline Tie-Up Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044244150-1.jpg",
                "_id": {
                    "$oid": "66ffdcd4584a4f778f5aeb9e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044244150-2.jpg",
                "_id": {
                    "$oid": "66ffdcd4584a4f778f5aeb9f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044244150-3.jpg",
                "_id": {
                    "$oid": "66ffdcd4584a4f778f5aeba0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044244150-4.jpg",
                "_id": {
                    "$oid": "66ffdcd4584a4f778f5aeba1"
                }
            }
        ],
        "c_brand": "SHRUGS",
        "c_price": 539,
        "c_description": "Black and white printed tie-up longline shrug ,has long sleeves, straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:17:24.600Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:17:24.600Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdd04584a4f778f5aeba8"
        },
        "c_name": "Rapid Dry & Antimicrobial Finish Training Sporty Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044292760-1.jpg",
                "_id": {
                    "$oid": "66ffdd04584a4f778f5aeba9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044292760-2.jpg",
                "_id": {
                    "$oid": "66ffdd04584a4f778f5aebaa"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044292760-3.jpg",
                "_id": {
                    "$oid": "66ffdd04584a4f778f5aebab"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 924,
        "c_description": "Black solid sporty jacket, has a mock collar, pockets, has a zip closure, long sleeves, straight hemline, polyester lining engineered with antimicrobial & rapid dry",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:18:12.852Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:18:12.852Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdd14584a4f778f5aebae"
        },
        "c_name": "Women Navy Blue Running Sporty Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044307990-1.jpg",
                "_id": {
                    "$oid": "66ffdd14584a4f778f5aebaf"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044307990-2.jpg",
                "_id": {
                    "$oid": "66ffdd14584a4f778f5aebb0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044307990-3.jpg",
                "_id": {
                    "$oid": "66ffdd14584a4f778f5aebb1"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 539,
        "c_description": "Navy blue solid sporty jacket\nHas a stand collar\n2 pockets ,has a zip closure\nlong sleeves\nstraight hemline\nMesh lining\nEngineered with rapid-dry and anti-microbial technology",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:18:28.201Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:18:28.201Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdd20584a4f778f5aebb4"
        },
        "c_name": "Women Teal Blue Solid Quilted Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044319895-1.jpg",
                "_id": {
                    "$oid": "66ffdd20584a4f778f5aebb5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044319895-2.jpg",
                "_id": {
                    "$oid": "66ffdd20584a4f778f5aebb6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044319895-3.jpg",
                "_id": {
                    "$oid": "66ffdd20584a4f778f5aebb7"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 942,
        "c_description": "Teal blue solid quilted jacket, has a stand collar, 3 pockets ,has a zip closure, long sleeves, straight hemline, polyester lining",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:18:40.132Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:18:40.132Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdd2b584a4f778f5aebba"
        },
        "c_name": "Lifestyle Colourblocked Rapid Dry & Antimicrobial Sporty Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044331189-1.jpg",
                "_id": {
                    "$oid": "66ffdd2b584a4f778f5aebbb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044331189-2.jpg",
                "_id": {
                    "$oid": "66ffdd2b584a4f778f5aebbc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044331189-3.jpg",
                "_id": {
                    "$oid": "66ffdd2b584a4f778f5aebbd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044331189-4.jpg",
                "_id": {
                    "$oid": "66ffdd2b584a4f778f5aebbe"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 619,
        "c_description": "Pink & white colourblocked sporty jacket, has a mock collar, 2 pockets ,has a zip closure, long sleeves, straight hemline, polyester lining engineered with antimicrobial & rapid dry",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:18:51.357Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:18:51.357Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdd38584a4f778f5aebc1"
        },
        "c_name": "Striped Tailored Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044344273-1.jpg",
                "_id": {
                    "$oid": "66ffdd38584a4f778f5aebc2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044344273-2.jpg",
                "_id": {
                    "$oid": "66ffdd38584a4f778f5aebc3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044344273-3.jpg",
                "_id": {
                    "$oid": "66ffdd38584a4f778f5aebc4"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 4074,
        "c_description": "Off white striped tailored jacket, has a spread collar, 2 pockets ,has a button closure, long sleeves, straight hemline, cotton lining engineered with no",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:19:04.449Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:19:04.449Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdd43584a4f778f5aebc7"
        },
        "c_name": "Women Blue Solid Denim Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044355513-1.jpg",
                "_id": {
                    "$oid": "66ffdd43584a4f778f5aebc8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044355513-2.jpg",
                "_id": {
                    "$oid": "66ffdd43584a4f778f5aebc9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044355513-3.jpg",
                "_id": {
                    "$oid": "66ffdd43584a4f778f5aebca"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044355513-4.jpg",
                "_id": {
                    "$oid": "66ffdd43584a4f778f5aebcb"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 735,
        "c_description": "Blue solid jacket, has a spread collar, 4 pockets, snap button closure, long sleeves, straight hem, and unlined lining",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:19:15.768Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:19:15.768Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdd99becb4652de394860"
        },
        "c_name": "Red Floral Printed Oversize Shirt With Palazzo",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044441477-1.jpg",
                "_id": {
                    "$oid": "66ffdd99becb4652de394861"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044441477-2.jpg",
                "_id": {
                    "$oid": "66ffdd99becb4652de394862"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044441477-3.jpg",
                "_id": {
                    "$oid": "66ffdd99becb4652de394863"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044441477-4.jpg",
                "_id": {
                    "$oid": "66ffdd99becb4652de394864"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 1331,
        "c_description": "This co-ords consists of shirt and Palazzo\nRed printed shirt, has shirt collar, full sleeves button closure and regular waist hem\nRed printed mid-rise Palazzo, has drawstring closure and straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:20:41.899Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:20:41.899Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdda7becb4652de394867"
        },
        "c_name": "Embellished V Neck Tunic With Palazzo",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044455291-1.jpg",
                "_id": {
                    "$oid": "66ffdda7becb4652de394868"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044455291-2.jpg",
                "_id": {
                    "$oid": "66ffdda7becb4652de394869"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044455291-3.jpg",
                "_id": {
                    "$oid": "66ffdda7becb4652de39486a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044455291-4.jpg",
                "_id": {
                    "$oid": "66ffdda7becb4652de39486b"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 2008,
        "c_description": "This co-ords consists of tunic and palazzo\nPink solid top with embellished detail, V-neck has long puff sleeves, slip-on closure and flared hem\nPink solid mid-rise palazzo, has slip-on closure and flared hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:20:55.694Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:20:55.694Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffddb2becb4652de39486e"
        },
        "c_name": "T-shirt With Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044466517-1.jpg",
                "_id": {
                    "$oid": "66ffddb2becb4652de39486f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044466517-2.jpg",
                "_id": {
                    "$oid": "66ffddb2becb4652de394870"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044466517-3.jpg",
                "_id": {
                    "$oid": "66ffddb2becb4652de394871"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044466517-4.jpg",
                "_id": {
                    "$oid": "66ffddb2becb4652de394872"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 689,
        "c_description": "This co-ord set consists of a T-shirt & Shorts \nPurple solid T-shirt, has a round neck, short, regular sleeves\nPurple solid Trousers, has 2 pockets and a elasticated waistband with a slip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:21:06.747Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:21:06.747Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffddbdbecb4652de394875"
        },
        "c_name": "Ethnic Motifs Printed Pure Cotton Top With Shorts & Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044477152-1.jpg",
                "_id": {
                    "$oid": "66ffddbdbecb4652de394876"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044477152-2.jpg",
                "_id": {
                    "$oid": "66ffddbdbecb4652de394877"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044477152-3.jpg",
                "_id": {
                    "$oid": "66ffddbdbecb4652de394878"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 1149,
        "c_description": "This co-ords consists of a top, shorts and shirt\nBeige, green and maroon ethnic motifs printed top, has a adjustable shoulder strap neck\nBeige, green and maroon ethnic motifsprinted shorts, has an elasticated waistband with slip-on closure\nComes with jacket",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:21:17.342Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:21:17.342Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffddc8becb4652de39487b"
        },
        "c_name": "Navy Blue Floral Printed Shirt Collar Tunic With Trousers Co-Ords",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044488363-1.jpg",
                "_id": {
                    "$oid": "66ffddc8becb4652de39487c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044488363-2.jpg",
                "_id": {
                    "$oid": "66ffddc8becb4652de39487d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044488363-3.jpg",
                "_id": {
                    "$oid": "66ffddc8becb4652de39487e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044488363-4.jpg",
                "_id": {
                    "$oid": "66ffddc8becb4652de39487f"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 1154,
        "c_description": "This co-ords consists of tunic & Trousers\nBlack & Grey printed tunic, has shirt collar, full sleeves, full placket button closure and regular waist hem\nBlack & grey printed mid-rise trousers, has Partially elasticated waistband, 2 side Pockets and flared hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:21:28.469Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:21:28.469Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffddd4becb4652de394882"
        },
        "c_name": "Abstract Printed Tunic With Trousers & Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044500630-1.jpg",
                "_id": {
                    "$oid": "66ffddd4becb4652de394883"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044500630-2.jpg",
                "_id": {
                    "$oid": "66ffddd4becb4652de394884"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044500630-3.jpg",
                "_id": {
                    "$oid": "66ffddd4becb4652de394885"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044500630-4.jpg",
                "_id": {
                    "$oid": "66ffddd4becb4652de394886"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 1310,
        "c_description": "This co-ords consists of a tunic, trousers and shrug\nGreen & cream coloured abstract printed tunic, has a v-neck and sleeveless sleeves with slip-on closure\nGreen & cream coloured abstract printed trousers, has an elasticated waistband with slip-on closure\nComes with green & cream coloured printed shrug",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:21:40.855Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:21:40.855Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdde0becb4652de394889"
        },
        "c_name": "Embroidered Top With Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044511928-1.jpg",
                "_id": {
                    "$oid": "66ffdde0becb4652de39488a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044511928-2.jpg",
                "_id": {
                    "$oid": "66ffdde0becb4652de39488b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044511928-3.jpg",
                "_id": {
                    "$oid": "66ffdde0becb4652de39488c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044511928-4.jpg",
                "_id": {
                    "$oid": "66ffdde0becb4652de39488d"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 991,
        "c_description": "This co-ords consists of a top and trousers\nBlue embroidered top, has a shirt collar neck and long sleeves, Secured with button closure\nBlue embroidered trousers, has an elasticated waistband with slip-on closure and 2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:21:52.123Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:21:52.123Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffddeabecb4652de394890"
        },
        "c_name": "Floral Printed Pack of 3 Co-Ords Set",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044522449-1.jpg",
                "_id": {
                    "$oid": "66ffddeabecb4652de394891"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044522449-2.jpg",
                "_id": {
                    "$oid": "66ffddeabecb4652de394892"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044522449-3.jpg",
                "_id": {
                    "$oid": "66ffddeabecb4652de394893"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044522449-4.jpg",
                "_id": {
                    "$oid": "66ffddeabecb4652de394894"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 704,
        "c_description": "\"This co-ords consists of top, shrug and shorts\n Blue printed top, has shoulder strap, sleeveless, slip-on closure and cropped waist hem\n Blue printed mid-rise shorts, has slip-on closure and straight hem with tie-up details\n Blue printed shrug, has front open, half sleeves, slip-on closure and regular waist hem\n \n \n Material\"",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:22:02.758Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:22:02.758Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffddf9becb4652de394897"
        },
        "c_name": "Shirt & Skirt Co-Ords",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044536803-1.jpg",
                "_id": {
                    "$oid": "66ffddf9becb4652de394898"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044536803-2.jpg",
                "_id": {
                    "$oid": "66ffddf9becb4652de394899"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044536803-3.jpg",
                "_id": {
                    "$oid": "66ffddf9becb4652de39489a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044536803-4.jpg",
                "_id": {
                    "$oid": "66ffddf9becb4652de39489b"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 1349,
        "c_description": "This co-ords consists of Shirt and Skirts\nTeal solid shirt, has Shirt collar Short sleeves full placket & Button closure\nTeal Solid mid-rise Skirt has Slip on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:22:17.168Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:22:17.168Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffde06becb4652de39489e"
        },
        "c_name": "Black Floral Printed Round Neck Top With Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044550707-1.jpg",
                "_id": {
                    "$oid": "66ffde06becb4652de39489f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044550707-2.jpg",
                "_id": {
                    "$oid": "66ffde06becb4652de3948a0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044550707-3.jpg",
                "_id": {
                    "$oid": "66ffde06becb4652de3948a1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044550707-4.jpg",
                "_id": {
                    "$oid": "66ffde06becb4652de3948a2"
                }
            }
        ],
        "c_brand": "CO-ORDS",
        "c_price": 1504,
        "c_description": "This co-ords consists of top and trousers\nBlack & beige floral printed top, has round neck,sleeveless, and regular waist hem\nBlack mid-rise trousers, has slip-on closure and straight hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:22:30.928Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:22:30.928Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdecfbecb4652de3948b3"
        },
        "c_name": "Livin Striped Straight Fit Chinos Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044751378-1.jpg",
                "_id": {
                    "$oid": "66ffdecfbecb4652de3948b4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044751378-2.jpg",
                "_id": {
                    "$oid": "66ffdecfbecb4652de3948b5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044751378-3.jpg",
                "_id": {
                    "$oid": "66ffdecfbecb4652de3948b6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044751378-4.jpg",
                "_id": {
                    "$oid": "66ffdecfbecb4652de3948b7"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 1794,
        "c_description": "Navy blue knitted chinos\nStraight fit\nMid-rise\nLength: regular\nPattern: solid striped\nFlat-front, with no pleats design\nFeature: plain\n4 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:25:51.550Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:25:51.550Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdee0becb4652de3948ba"
        },
        "c_name": "Women Pink Solid Comfort Wide Leg Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044768353-1.jpg",
                "_id": {
                    "$oid": "66ffdee0becb4652de3948bb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044768353-2.jpg",
                "_id": {
                    "$oid": "66ffdee0becb4652de3948bc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044768353-3.jpg",
                "_id": {
                    "$oid": "66ffdee0becb4652de3948bd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044768353-4.jpg",
                "_id": {
                    "$oid": "66ffdee0becb4652de3948be"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 2390,
        "c_description": "Pink woven parallel\nFlared, Wide Leg\nBrand Fit: comfort\nMid-rise\nLength: cropped\nPattern: solid\nFlat-front, with no pleats design\nFeature: plain\n1 pocket",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:26:08.515Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:26:08.515Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf1cbecb4652de3948d0"
        },
        "c_name": "Women Relaxed Straight Fit Linen Mid-Rise Formal Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044828263-1.jpg",
                "_id": {
                    "$oid": "66ffdf1cbecb4652de3948d1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044828263-2.jpg",
                "_id": {
                    "$oid": "66ffdf1cbecb4652de3948d2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044828263-3.jpg",
                "_id": {
                    "$oid": "66ffdf1cbecb4652de3948d3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044828263-4.jpg",
                "_id": {
                    "$oid": "66ffdf1cbecb4652de3948d4"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 2087,
        "c_description": "Navy blue woven formal trousers\nStraight fit\nBrand Fit: relaxed\nMid-rise\nLength: regular\nPattern: solid\nFlat-front, with no pleats design\nFeature: travel features\n2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:27:08.444Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:27:08.444Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf2c584a4f778f5aebf2"
        },
        "c_name": "Women Wide Twill Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044844339-1.jpg",
                "_id": {
                    "$oid": "66ffdf2c584a4f778f5aebf3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044844339-2.jpg",
                "_id": {
                    "$oid": "66ffdf2c584a4f778f5aebf4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044844339-3.jpg",
                "_id": {
                    "$oid": "66ffdf2c584a4f778f5aebf5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044844339-4.jpg",
                "_id": {
                    "$oid": "66ffdf2c584a4f778f5aebf6"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 1499,
        "c_description": "Trousers in cotton twill with a high waist, zip fly and button, front and back pockets and wide, straight legs.",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:27:24.461Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:27:24.461Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf3a584a4f778f5aebf9"
        },
        "c_name": "Women Plus Size Flared Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044858269-1.jpg",
                "_id": {
                    "$oid": "66ffdf3a584a4f778f5aebfa"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044858269-2.jpg",
                "_id": {
                    "$oid": "66ffdf3a584a4f778f5aebfb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044858269-3.jpg",
                "_id": {
                    "$oid": "66ffdf3a584a4f778f5aebfc"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 2324,
        "c_description": "Black woven trousers\nFlared\nMid-rise\nLength: regular\nPattern: solid\nFlat-front, with no pleats design\nFeature: plain",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:27:38.412Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:27:38.412Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf46584a4f778f5aebff"
        },
        "c_name": "Women Slim Fit Pleated Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044870228-1.jpg",
                "_id": {
                    "$oid": "66ffdf46584a4f778f5aec00"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044870228-2.jpg",
                "_id": {
                    "$oid": "66ffdf46584a4f778f5aec01"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044870228-3.jpg",
                "_id": {
                    "$oid": "66ffdf46584a4f778f5aec02"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044870228-4.jpg",
                "_id": {
                    "$oid": "66ffdf46584a4f778f5aec03"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 1649,
        "c_description": "Green woven trousers\nSlim fit\nMid-rise\nLength: regular\nPattern: solid\nPleated design\nFeature: plain\n2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:27:50.334Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:27:50.334Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf52584a4f778f5aec06"
        },
        "c_name": "High-Rise Parallel Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044882423-1.jpg",
                "_id": {
                    "$oid": "66ffdf52584a4f778f5aec07"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044882423-2.jpg",
                "_id": {
                    "$oid": "66ffdf52584a4f778f5aec08"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044882423-3.jpg",
                "_id": {
                    "$oid": "66ffdf52584a4f778f5aec09"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044882423-4.jpg",
                "_id": {
                    "$oid": "66ffdf52584a4f778f5aec0a"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 1649,
        "c_description": "Green woven parallel\nRegular fit\nHigh-rise\nLength: regular\nPattern: solid\nFlat-front, with no pleats design\nFeature: plain\n2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:28:02.569Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:28:02.569Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf61584a4f778f5aec0d"
        },
        "c_name": "Women Smart Straight Fit High-Rise Wide Leg Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044897788-1.jpg",
                "_id": {
                    "$oid": "66ffdf61584a4f778f5aec0e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044897788-2.jpg",
                "_id": {
                    "$oid": "66ffdf61584a4f778f5aec0f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044897788-3.jpg",
                "_id": {
                    "$oid": "66ffdf61584a4f778f5aec10"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 989,
        "c_description": "Black machine weave wide leg\nStraight fit\nBrand Fit: smart\nHigh-rise\nLength: regular\nPattern: solid\nPleated design\nFeature: plain\n2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:28:17.909Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:28:17.909Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf6b584a4f778f5aec13"
        },
        "c_name": "Women Floral Printed Slim Fit Cotton Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044907735-1.jpg",
                "_id": {
                    "$oid": "66ffdf6b584a4f778f5aec14"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044907735-2.jpg",
                "_id": {
                    "$oid": "66ffdf6b584a4f778f5aec15"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044907735-3.jpg",
                "_id": {
                    "$oid": "66ffdf6b584a4f778f5aec16"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044907735-4.jpg",
                "_id": {
                    "$oid": "66ffdf6b584a4f778f5aec17"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 1799,
        "c_description": "Olive green woven trousers\nSlim fit\nMid-rise\nLength: cropped\nPattern: floral printed\nPleated design\nFeature: plain\n2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:28:27.882Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:28:27.882Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "66ffdf76584a4f778f5aec1a"
        },
        "c_name": "Women Geometric Printed Straight Fit High-Rise Culottes Trousers",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044918019-1.jpg",
                "_id": {
                    "$oid": "66ffdf76584a4f778f5aec1b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044918019-2.jpg",
                "_id": {
                    "$oid": "66ffdf76584a4f778f5aec1c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044918019-3.jpg",
                "_id": {
                    "$oid": "66ffdf76584a4f778f5aec1d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728044918019-4.jpg",
                "_id": {
                    "$oid": "66ffdf76584a4f778f5aec1e"
                }
            }
        ],
        "c_brand": {
            "$oid": "66ffde17becb4652de3948a6"
        },
        "c_price": 1259,
        "c_description": "Green & white woven culottes\nStraight fit\nHigh-rise\nLength: cropped\nPattern: geometric printed\nFlat-front, with no pleats design\nFeature: plain\n2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com/",
        "createdAt": {
            "$date": "2024-10-04T12:28:38.218Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T12:28:38.218Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67000e1ee4e9433c1c7fe981"
        },
        "c_name": "Abstract Printed Shoulder Straps Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728056861775-1.jpg",
                "_id": {
                    "$oid": "67000e1ee4e9433c1c7fe982"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728056861775-2.jpg",
                "_id": {
                    "$oid": "67000e1ee4e9433c1c7fe983"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728056861775-3.jpg",
                "_id": {
                    "$oid": "67000e1ee4e9433c1c7fe984"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728056861775-4.jpg",
                "_id": {
                    "$oid": "67000e1ee4e9433c1c7fe985"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 927,
        "c_description": "Brown and black abstract printed basic jumpsuit ,has a shoulder straps 2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T15:47:42.128Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T15:47:42.128Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67000eade4e9433c1c7fe98e"
        },
        "c_name": "Flared Party Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057004689-1.jpg",
                "_id": {
                    "$oid": "67000eade4e9433c1c7fe98f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057004689-2.jpg",
                "_id": {
                    "$oid": "67000eade4e9433c1c7fe990"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057004689-3.jpg",
                "_id": {
                    "$oid": "67000eade4e9433c1c7fe991"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057004689-4.jpg",
                "_id": {
                    "$oid": "67000eade4e9433c1c7fe992"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1609,
        "c_description": "Yellow solid wrap party jumpsuit, has shoulder straps, sleeveless, 2 pockets, and zip closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T15:50:05.083Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T15:50:05.083Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67000f2fe4e9433c1c7fe995"
        },
        "c_name": "Women White Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057135419-1.jpg",
                "_id": {
                    "$oid": "67000f2fe4e9433c1c7fe996"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057135419-2.jpg",
                "_id": {
                    "$oid": "67000f2fe4e9433c1c7fe997"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057135419-3.jpg",
                "_id": {
                    "$oid": "67000f2fe4e9433c1c7fe998"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057135419-4.jpg",
                "_id": {
                    "$oid": "67000f2fe4e9433c1c7fe999"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1079,
        "c_description": "White solid basic jumpsuit ,has a v-neck, three-quarter sleeves, 2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T15:52:15.739Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T15:52:15.739Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67000f7fe4e9433c1c7fe99c"
        },
        "c_name": "Embellished Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057215348-1.jpg",
                "_id": {
                    "$oid": "67000f7fe4e9433c1c7fe99d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057215348-2.jpg",
                "_id": {
                    "$oid": "67000f7fe4e9433c1c7fe99e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057215348-3.jpg",
                "_id": {
                    "$oid": "67000f7fe4e9433c1c7fe99f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057215348-4.jpg",
                "_id": {
                    "$oid": "67000f7fe4e9433c1c7fe9a0"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1199,
        "c_description": "Black & white embellished basic jumpsuit, has a shoulder straps",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T15:53:35.647Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T15:53:35.647Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700102de4e9433c1c7fe9a3"
        },
        "c_name": "Blue & Orange Foil Printed Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057389358-1.jpg",
                "_id": {
                    "$oid": "6700102de4e9433c1c7fe9a4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057389358-2.jpg",
                "_id": {
                    "$oid": "6700102de4e9433c1c7fe9a5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057389358-3.jpg",
                "_id": {
                    "$oid": "6700102de4e9433c1c7fe9a6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057389358-4.jpg",
                "_id": {
                    "$oid": "6700102de4e9433c1c7fe9a7"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 671,
        "c_description": "Blue and orange printed basic jumpsuit ,has a v-neck, short sleeves, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T15:56:29.796Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T15:56:29.796Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700108fe4e9433c1c7fe9aa"
        },
        "c_name": "Multicoloured Printed Playsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057486497-1.jpg",
                "_id": {
                    "$oid": "6700108fe4e9433c1c7fe9ab"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057486497-2.jpg",
                "_id": {
                    "$oid": "6700108fe4e9433c1c7fe9ac"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057486497-3.jpg",
                "_id": {
                    "$oid": "6700108fe4e9433c1c7fe9ad"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057486497-4.jpg",
                "_id": {
                    "$oid": "6700108fe4e9433c1c7fe9ae"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 637,
        "c_description": "Multicoloured printed playsuit ,has a v-neck, long sleeves, pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T15:58:07.042Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T15:58:07.042Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670011b98bbb02df023f3ad0"
        },
        "c_name": "Green & Black Printed Basic Jumpsuit with Ruffles",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057784848-1.jpg",
                "_id": {
                    "$oid": "670011b98bbb02df023f3ad1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057784848-2.jpg",
                "_id": {
                    "$oid": "670011b98bbb02df023f3ad2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057784848-3.jpg",
                "_id": {
                    "$oid": "670011b98bbb02df023f3ad3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057784848-4.jpg",
                "_id": {
                    "$oid": "670011b98bbb02df023f3ad4"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1155,
        "c_description": "Green and black printed basic jumpsuit with ruffles ,has a square neck, short sleeves, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:03:05.298Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:03:05.298Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670011d88bbb02df023f3ad7"
        },
        "c_name": "Embellished Sleeveless Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057815693-1.jpg",
                "_id": {
                    "$oid": "670011d88bbb02df023f3ad8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057815693-2.jpg",
                "_id": {
                    "$oid": "670011d88bbb02df023f3ad9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057815693-3.jpg",
                "_id": {
                    "$oid": "670011d88bbb02df023f3ada"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057815693-4.jpg",
                "_id": {
                    "$oid": "670011d88bbb02df023f3adb"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1119,
        "c_description": "Grey self design basic jumpsuit, has a shoulder straps, sleeveless",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:03:36.057Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:03:36.057Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670011fa8bbb02df023f3ade"
        },
        "c_name": "V-Neck Belted Sleek Utility Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057850032-1.jpg",
                "_id": {
                    "$oid": "670011fa8bbb02df023f3adf"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057850032-2.jpg",
                "_id": {
                    "$oid": "670011fa8bbb02df023f3ae0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057850032-3.jpg",
                "_id": {
                    "$oid": "670011fa8bbb02df023f3ae1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728057850032-4.jpg",
                "_id": {
                    "$oid": "670011fa8bbb02df023f3ae2"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1199,
        "c_description": "Olive green solid basic jumpsuit ,has a v-neck, long sleeves, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:04:10.261Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:04:10.261Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670012be8bbb02df023f3af1"
        },
        "c_name": "Rapid-Dry Running Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058045957-1.jpg",
                "_id": {
                    "$oid": "670012be8bbb02df023f3af2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058045957-2.jpg",
                "_id": {
                    "$oid": "670012be8bbb02df023f3af3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058045957-3.jpg",
                "_id": {
                    "$oid": "670012be8bbb02df023f3af4"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 896,
        "c_description": "Product design details:\nGreen solid running jacket\nHas a mock collar\n2 pockets\nZip closure\nLong sleeves\nStraight hemline\nMesh lining\nEngineered with Rapid-Dry and Antimicrobial technologies",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:07:26.153Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:07:26.153Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670012e88bbb02df023f3af7"
        },
        "c_name": "Lightweight Dry Fit Gym Sporty Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058088428-1.jpg",
                "_id": {
                    "$oid": "670012e88bbb02df023f3af8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058088428-2.jpg",
                "_id": {
                    "$oid": "670012e88bbb02df023f3af9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058088428-3.jpg",
                "_id": {
                    "$oid": "670012e88bbb02df023f3afa"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058088428-4.jpg",
                "_id": {
                    "$oid": "670012e88bbb02df023f3afb"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 826,
        "c_description": "Black solid lightweight sporty jacket, has a mock collar, 2 pockets ,has a zip closure, long sleeves, curved hemline, polyester lining",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:08:08.708Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:08:08.708Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700131a8bbb02df023f3afe"
        },
        "c_name": "Women Blue Washed Denim Trucker Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058138622-1.jpg",
                "_id": {
                    "$oid": "6700131a8bbb02df023f3aff"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058138622-2.jpg",
                "_id": {
                    "$oid": "6700131a8bbb02df023f3b00"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058138622-3.jpg",
                "_id": {
                    "$oid": "6700131a8bbb02df023f3b01"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 629,
        "c_description": "Blue washed denim trucker jacket, has a spread collar, 2 pockets, button closure, long sleeves, straight hem, unlined and has 2 mock pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:08:58.880Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:08:58.880Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670013508bbb02df023f3b04"
        },
        "c_name": "Women Grey Solid Padded Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058192661-1.jpg",
                "_id": {
                    "$oid": "670013508bbb02df023f3b05"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058192661-2.jpg",
                "_id": {
                    "$oid": "670013508bbb02df023f3b06"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058192661-3.jpg",
                "_id": {
                    "$oid": "670013508bbb02df023f3b07"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 819,
        "c_description": "Grey solid padded jacket, has a mock collar, 2 pockets, button and concealed zip closure, long sleeves, straight hem, and polyester lining",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:09:52.896Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:09:52.896Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670013768bbb02df023f3b0a"
        },
        "c_name": "The Life Co. Typography Varsity Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058230693-1.jpg",
                "_id": {
                    "$oid": "670013768bbb02df023f3b0b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058230693-2.jpg",
                "_id": {
                    "$oid": "670013768bbb02df023f3b0c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058230693-3.jpg",
                "_id": {
                    "$oid": "670013768bbb02df023f3b0d"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 859,
        "c_description": "Black typography self design varsity jacket with embroidered, has a mock collar, 2 pockets ,has a button closure, long sleeves, straight hemline, without lining engineered with no",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:10:30.901Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:10:30.901Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700148c8bbb02df023f3b1c"
        },
        "c_name": "Women Navy Blue Solid Padded Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058508766-1.jpg",
                "_id": {
                    "$oid": "6700148c8bbb02df023f3b1d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058508766-2.jpg",
                "_id": {
                    "$oid": "6700148c8bbb02df023f3b1e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058508766-3.jpg",
                "_id": {
                    "$oid": "6700148c8bbb02df023f3b1f"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 939,
        "c_description": "Navy Blue solid padded jacket, has a mock collar, 2 pockets, snap button with zip closure, long sleeves, straight hem, and polyester lining",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:15:08.935Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:15:08.935Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670014b48bbb02df023f3b22"
        },
        "c_name": "Brown Typography Embroidered Mock Collar Relaxed Varsity Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058548644-1.jpg",
                "_id": {
                    "$oid": "670014b48bbb02df023f3b23"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058548644-2.jpg",
                "_id": {
                    "$oid": "670014b48bbb02df023f3b24"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058548644-3.jpg",
                "_id": {
                    "$oid": "670014b48bbb02df023f3b25"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058548644-4.jpg",
                "_id": {
                    "$oid": "670014b48bbb02df023f3b26"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 649,
        "c_description": "Brown & off white typography embroidered varsity jacket, has a mock collar, long sleeves, 2 pockets, has a snap button closure, straight hemline",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:15:48.898Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:15:48.898Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670014fc8bbb02df023f3b29"
        },
        "c_name": "The Lifestyle Co. Applique Varsity Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058620430-1.jpg",
                "_id": {
                    "$oid": "670014fc8bbb02df023f3b2a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058620430-2.jpg",
                "_id": {
                    "$oid": "670014fc8bbb02df023f3b2b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058620430-3.jpg",
                "_id": {
                    "$oid": "670014fc8bbb02df023f3b2c"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 799,
        "c_description": "Maroon solid varsity jacket, has a applique, stand collar, 2 pockets ,has a snap button closure, long sleeves, straight hemline, without lining",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:17:00.602Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:17:00.602Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700152c8bbb02df023f3b2f"
        },
        "c_name": "Women Navy Blue Washed Cropped Denim Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058668251-1.jpg",
                "_id": {
                    "$oid": "6700152c8bbb02df023f3b30"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058668251-2.jpg",
                "_id": {
                    "$oid": "6700152c8bbb02df023f3b31"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058668251-3.jpg",
                "_id": {
                    "$oid": "6700152c8bbb02df023f3b32"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058668251-4.jpg",
                "_id": {
                    "$oid": "6700152c8bbb02df023f3b33"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 999,
        "c_description": "Navy Blue washed cropped denim jacket, has a spread collar, 4 pockets, button closure, long sleeves, straight hem, and unlined lining",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:17:48.539Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:17:48.539Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700155d8bbb02df023f3b36"
        },
        "c_name": "Women Maroon Outdoor Sporty Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058717541-1.jpg",
                "_id": {
                    "$oid": "6700155d8bbb02df023f3b37"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058717541-2.jpg",
                "_id": {
                    "$oid": "6700155d8bbb02df023f3b38"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058717541-3.jpg",
                "_id": {
                    "$oid": "6700155d8bbb02df023f3b39"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 689,
        "c_description": "Maroon solid outdoor jacket, has a mock collar, 2 pockets, a zip closure, long sleeves, straight hemline, polyester lining\nEngineered with Rapid-Dry technology",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:18:37.805Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:18:37.805Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700158d8bbb02df023f3b3c"
        },
        "c_name": "Rapid-Dry & Antimicrobial Finish Sporty Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058764846-1.jpg",
                "_id": {
                    "$oid": "6700158d8bbb02df023f3b3d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058764846-2.jpg",
                "_id": {
                    "$oid": "6700158d8bbb02df023f3b3e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058764846-3.jpg",
                "_id": {
                    "$oid": "6700158d8bbb02df023f3b3f"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 759,
        "c_description": "Cream-coloured solid sporty jacket, has a mock collar, 2 pockets, has a zip closure, long sleeves, straight hemline, mesh lining engineered with rapid-dry & antimicrobial finish",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:19:25.077Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:19:25.077Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670015be8bbb02df023f3b42"
        },
        "c_name": "Mock Collar Crop Puffer Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058814520-1.jpg",
                "_id": {
                    "$oid": "670015be8bbb02df023f3b43"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058814520-2.jpg",
                "_id": {
                    "$oid": "670015be8bbb02df023f3b44"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058814520-3.jpg",
                "_id": {
                    "$oid": "670015be8bbb02df023f3b45"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058814520-4.jpg",
                "_id": {
                    "$oid": "670015be8bbb02df023f3b46"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 849,
        "c_description": "Black solid puffer jacket, has a mock collar, 2 pockets ,has a zip closure, long sleeves, straight hemline, polyester lining",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:20:14.721Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:20:14.721Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670015e58bbb02df023f3b49"
        },
        "c_name": "Pink Insulator Longline Tailored Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058853135-1.jpg",
                "_id": {
                    "$oid": "670015e58bbb02df023f3b4a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058853135-2.jpg",
                "_id": {
                    "$oid": "670015e58bbb02df023f3b4b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058853135-3.jpg",
                "_id": {
                    "$oid": "670015e58bbb02df023f3b4c"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 1149,
        "c_description": "Pink solid insulator tailored jacket, has a spread collar, 2 pockets ,has a snap button closure, long sleeves, curved hemline, woollen lining",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:20:53.337Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:20:53.337Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700160f8bbb02df023f3b4f"
        },
        "c_name": "The Lifestyle Co. Women Beige & Brown Checked Faux Fur Trim Tailored Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058895151-1.jpg",
                "_id": {
                    "$oid": "6700160f8bbb02df023f3b50"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058895151-2.jpg",
                "_id": {
                    "$oid": "6700160f8bbb02df023f3b51"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058895151-3.jpg",
                "_id": {
                    "$oid": "6700160f8bbb02df023f3b52"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058895151-4.jpg",
                "_id": {
                    "$oid": "6700160f8bbb02df023f3b53"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 1484,
        "c_description": "Beige & brown checked tailored jacket with faux fur trim, has a spread collar, 2 pockets ,has a button closure, long sleeves, straight hemline, polyester lining",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:21:35.354Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:21:35.354Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670016298bbb02df023f3b56"
        },
        "c_name": "Typography Printed Stand Collar Bomber Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058921252-1.jpg",
                "_id": {
                    "$oid": "670016298bbb02df023f3b57"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058921252-2.jpg",
                "_id": {
                    "$oid": "670016298bbb02df023f3b58"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058921252-3.jpg",
                "_id": {
                    "$oid": "670016298bbb02df023f3b59"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058921252-4.jpg",
                "_id": {
                    "$oid": "670016298bbb02df023f3b5a"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 494,
        "c_description": "Black and blue graphic printed bomber jacket, has a stand collar, has a zip closure, long sleeves, straight hemline, without lining",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:22:01.540Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:22:01.540Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670016488bbb02df023f3b5d"
        },
        "c_name": "Women Teal Green Pure Cotton Crop Denim Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058952094-1.jpg",
                "_id": {
                    "$oid": "670016488bbb02df023f3b5e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058952094-2.jpg",
                "_id": {
                    "$oid": "670016488bbb02df023f3b5f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728058952094-3.jpg",
                "_id": {
                    "$oid": "670016488bbb02df023f3b60"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 699,
        "c_description": "Teal green solid denim jacket\nSpread collar\n2 pockets\nHas a button closure\nLong sleeves\nStraight hemline\nWithout lining\n100% Cotton\nMachine Wash\nThe model (height 5'8) is wearing a size S",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:22:32.361Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:22:32.361Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700167c8bbb02df023f3b67"
        },
        "c_name": "Women Floral Crop Bomber with Embroidered Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059004121-1.jpg",
                "_id": {
                    "$oid": "6700167c8bbb02df023f3b68"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059004121-2.jpg",
                "_id": {
                    "$oid": "6700167c8bbb02df023f3b69"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059004121-3.jpg",
                "_id": {
                    "$oid": "6700167c8bbb02df023f3b6a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059004121-4.jpg",
                "_id": {
                    "$oid": "6700167c8bbb02df023f3b6b"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 999,
        "c_description": "Black floral printed bomber with embroidered, has a stand collar, na pockets ,has a button closure, long sleeves, straight hemline, without lining",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:23:24.404Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:23:24.404Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670016a58bbb02df023f3b6e"
        },
        "c_name": "Coffee Brown Windcheater Bomber Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059044817-1.jpg",
                "_id": {
                    "$oid": "670016a58bbb02df023f3b6f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059044817-2.jpg",
                "_id": {
                    "$oid": "670016a58bbb02df023f3b70"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059044817-3.jpg",
                "_id": {
                    "$oid": "670016a58bbb02df023f3b71"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059044817-4.jpg",
                "_id": {
                    "$oid": "670016a58bbb02df023f3b72"
                }
            }
        ],
        "c_brand": "JACKETS",
        "c_price": 1149,
        "c_description": "Coffee brown solid windcheater bomber jacket, has a spread collar, 2 pockets ,has a zip closure, long sleeves, straight hemline, polyester lining",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:24:05.037Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:24:05.037Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670016ec8bbb02df023f3b79"
        },
        "c_name": "Hooded Crop Tailored Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059116208-1.jpg",
                "_id": {
                    "$oid": "670016ec8bbb02df023f3b7a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059116208-2.jpg",
                "_id": {
                    "$oid": "670016ec8bbb02df023f3b7b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059116208-3.jpg",
                "_id": {
                    "$oid": "670016ec8bbb02df023f3b7c"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 863,
        "c_description": "Blue solid tailored jacket, has a hood, 2 pockets, a zip closure, long sleeves with printed patchwork, straight hemline, polyester lining with inner pocket",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:25:16.398Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:25:16.398Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700178a8bbb02df023f3c18"
        },
        "c_name": "Puff Sleeves Basic Jumpsuit Comes With a Belt",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059274135-1.jpg",
                "_id": {
                    "$oid": "6700178a8bbb02df023f3c19"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059274135-2.jpg",
                "_id": {
                    "$oid": "6700178a8bbb02df023f3c1a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059274135-3.jpg",
                "_id": {
                    "$oid": "6700178a8bbb02df023f3c1b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059274135-4.jpg",
                "_id": {
                    "$oid": "6700178a8bbb02df023f3c1c"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 949,
        "c_description": "Olive green solid basic jumpsuit with waist tie-ups ,has a cuban collar, short puff sleeves, 1 pocket",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:27:54.374Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:27:54.374Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670017a58bbb02df023f3c1f"
        },
        "c_name": "Self Design Cut-Out Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059300899-1.jpg",
                "_id": {
                    "$oid": "670017a58bbb02df023f3c20"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059300899-2.jpg",
                "_id": {
                    "$oid": "670017a58bbb02df023f3c21"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059300899-3.jpg",
                "_id": {
                    "$oid": "670017a58bbb02df023f3c22"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059300899-4.jpg",
                "_id": {
                    "$oid": "670017a58bbb02df023f3c23"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 430,
        "c_description": "Blue self design basic jumpsuit ,has a shoulder straps, sleeveless, cut-out detail",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:28:21.118Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:28:21.118Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670017df8bbb02df023f3c2b"
        },
        "c_name": "Blue Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059359621-1.jpg",
                "_id": {
                    "$oid": "670017df8bbb02df023f3c2c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059359621-2.jpg",
                "_id": {
                    "$oid": "670017df8bbb02df023f3c2d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059359621-3.jpg",
                "_id": {
                    "$oid": "670017df8bbb02df023f3c2e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059359621-4.jpg",
                "_id": {
                    "$oid": "670017df8bbb02df023f3c2f"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1285,
        "c_description": "Blue solid basic jumpsuit ,has a round neck, sleeveless, na pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:29:19.823Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:29:19.823Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670017fe8bbb02df023f3c32"
        },
        "c_name": "Green ethnic Motifs Printed Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059390534-1.jpg",
                "_id": {
                    "$oid": "670017fe8bbb02df023f3c33"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059390534-2.jpg",
                "_id": {
                    "$oid": "670017fe8bbb02df023f3c34"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059390534-3.jpg",
                "_id": {
                    "$oid": "670017fe8bbb02df023f3c35"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059390534-4.jpg",
                "_id": {
                    "$oid": "670017fe8bbb02df023f3c36"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1115,
        "c_description": "Green and red ethnic motifs printed basic jumpsuit ,has a tie up neck, three-quarter sleeves, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:29:50.845Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:29:50.845Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670018188bbb02df023f3c39"
        },
        "c_name": "Black Halter Neck Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059416675-1.jpg",
                "_id": {
                    "$oid": "670018188bbb02df023f3c3a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059416675-2.jpg",
                "_id": {
                    "$oid": "670018188bbb02df023f3c3b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059416675-3.jpg",
                "_id": {
                    "$oid": "670018188bbb02df023f3c3c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059416675-4.jpg",
                "_id": {
                    "$oid": "670018188bbb02df023f3c3d"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 674,
        "c_description": "Black solid basic jumpsuit ,has a halter neck, sleeveless, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:30:16.871Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:30:16.871Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670018358bbb02df023f3c40"
        },
        "c_name": "Shirt Collar Long Sleeve Waist Tie-Ups Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059444762-1.jpg",
                "_id": {
                    "$oid": "670018358bbb02df023f3c41"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059444762-2.jpg",
                "_id": {
                    "$oid": "670018358bbb02df023f3c42"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059444762-3.jpg",
                "_id": {
                    "$oid": "670018358bbb02df023f3c43"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059444762-4.jpg",
                "_id": {
                    "$oid": "670018358bbb02df023f3c44"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 701,
        "c_description": "Olive green solid basic jumpsuit ,has a shirt collar, long sleeves, button closure, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:30:45.022Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:30:45.022Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670018588bbb02df023f3c47"
        },
        "c_name": "Floral Printed Puff Sleeves Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059480256-1.jpg",
                "_id": {
                    "$oid": "670018588bbb02df023f3c48"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059480256-2.jpg",
                "_id": {
                    "$oid": "670018588bbb02df023f3c49"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059480256-3.jpg",
                "_id": {
                    "$oid": "670018588bbb02df023f3c4a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059480256-4.jpg",
                "_id": {
                    "$oid": "670018588bbb02df023f3c4b"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1187,
        "c_description": "Black and pink floral printed basic jumpsuit ,has a shirt collar, short puff sleeves, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:31:20.573Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:31:20.573Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670018828bbb02df023f3c4e"
        },
        "c_name": "Brighter Basics One Shoulder Cut-Out Detail Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059521933-1.jpg",
                "_id": {
                    "$oid": "670018828bbb02df023f3c4f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059521933-2.jpg",
                "_id": {
                    "$oid": "670018828bbb02df023f3c50"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059521933-3.jpg",
                "_id": {
                    "$oid": "670018828bbb02df023f3c51"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 824,
        "c_description": "Product Details:\nFeaturing a sleek cut-out at waist and one shoulder design, this jumpsuit hugs your body perfectly! Pair it with open toe heels & silver hoops for a nigh out with friends.\nFeatures:\nMagenta solid basic jumpsuit\nOne shoulder\nSleeveless\nCut-out detail\nConcealed zip closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:32:02.124Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:32:02.124Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700189f8bbb02df023f3c54"
        },
        "c_name": "Pink & Black Printed Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059551384-1.jpg",
                "_id": {
                    "$oid": "6700189f8bbb02df023f3c55"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059551384-2.jpg",
                "_id": {
                    "$oid": "6700189f8bbb02df023f3c56"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059551384-3.jpg",
                "_id": {
                    "$oid": "6700189f8bbb02df023f3c57"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059551384-4.jpg",
                "_id": {
                    "$oid": "6700189f8bbb02df023f3c58"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 863,
        "c_description": "Pink and black printed playsuit with waist tie-ups ,has a boat neck, short sleeves",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:32:31.786Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:32:31.786Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670018be8bbb02df023f3c5b"
        },
        "c_name": "V-Neck Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059581930-1.jpg",
                "_id": {
                    "$oid": "670018be8bbb02df023f3c5c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059581930-2.jpg",
                "_id": {
                    "$oid": "670018be8bbb02df023f3c5d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059581930-3.jpg",
                "_id": {
                    "$oid": "670018be8bbb02df023f3c5e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059581930-4.jpg",
                "_id": {
                    "$oid": "670018be8bbb02df023f3c5f"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 989,
        "c_description": "White & black solid basic jumpsuit ,has a v-neck, short sleeves, 2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:33:02.137Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:33:02.137Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670018e28bbb02df023f3c62"
        },
        "c_name": "White & Pink Striped Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059618479-1.jpg",
                "_id": {
                    "$oid": "670018e28bbb02df023f3c63"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059618479-2.jpg",
                "_id": {
                    "$oid": "670018e28bbb02df023f3c64"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059618479-3.jpg",
                "_id": {
                    "$oid": "670018e28bbb02df023f3c65"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059618479-4.jpg",
                "_id": {
                    "$oid": "670018e28bbb02df023f3c66"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 909,
        "c_description": "White and pink striped basic jumpsuit with waist tie-ups ,has a square neck, sleeveless, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:33:38.839Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:33:38.839Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670019018bbb02df023f3c69"
        },
        "c_name": "Floral Printed Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059649141-1.jpg",
                "_id": {
                    "$oid": "670019018bbb02df023f3c6a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059649141-2.jpg",
                "_id": {
                    "$oid": "670019018bbb02df023f3c6b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059649141-3.jpg",
                "_id": {
                    "$oid": "670019018bbb02df023f3c6c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059649141-4.jpg",
                "_id": {
                    "$oid": "670019018bbb02df023f3c6d"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1305,
        "c_description": "Red and coral printed basic jumpsuit ,has a v-neck, short sleeves, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:34:09.355Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:34:09.355Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670019258bbb02df023f3c70"
        },
        "c_name": "Green Checked Waist Tie-Ups Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059685304-1.jpg",
                "_id": {
                    "$oid": "670019258bbb02df023f3c71"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059685304-2.jpg",
                "_id": {
                    "$oid": "670019258bbb02df023f3c72"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059685304-3.jpg",
                "_id": {
                    "$oid": "670019258bbb02df023f3c73"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059685304-4.jpg",
                "_id": {
                    "$oid": "670019258bbb02df023f3c74"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 615,
        "c_description": "Green checked basic jumpsuit , tie up detail, has a round neck, long sleeves, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:34:45.570Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:34:45.570Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670019408bbb02df023f3c77"
        },
        "c_name": "Women Printed Sleeveless Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059712451-1.jpg",
                "_id": {
                    "$oid": "670019408bbb02df023f3c78"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059712451-2.jpg",
                "_id": {
                    "$oid": "670019408bbb02df023f3c79"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059712451-3.jpg",
                "_id": {
                    "$oid": "670019408bbb02df023f3c7a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059712451-4.jpg",
                "_id": {
                    "$oid": "670019408bbb02df023f3c7b"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 799,
        "c_description": "Blue and grey printed basic jumpsuit with waist tie-ups ,has a round neck, sleeveless, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:35:12.704Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:35:12.704Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670019848bbb02df023f3c7e"
        },
        "c_name": "Yellow Overlaped One Shoulder Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059780415-1.jpg",
                "_id": {
                    "$oid": "670019848bbb02df023f3c7f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059780415-2.jpg",
                "_id": {
                    "$oid": "670019848bbb02df023f3c80"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059780415-3.jpg",
                "_id": {
                    "$oid": "670019848bbb02df023f3c81"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059780415-4.jpg",
                "_id": {
                    "$oid": "670019848bbb02df023f3c82"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1048,
        "c_description": "Yellow solid basic jumpsuit ,has a one shoulder, sleeveless, 2 pockets, zip closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:36:20.625Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:36:20.625Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670019a38bbb02df023f3c85"
        },
        "c_name": "Mock Neck Pocket Detail Zipped Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059810785-1.jpg",
                "_id": {
                    "$oid": "670019a38bbb02df023f3c86"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059810785-2.jpg",
                "_id": {
                    "$oid": "670019a38bbb02df023f3c87"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059810785-3.jpg",
                "_id": {
                    "$oid": "670019a38bbb02df023f3c88"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 727,
        "c_description": "Grey solid basic jumpsuit, has a mock neck, sleeveless, pocket detailing, and regular fit",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:36:51.041Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:36:51.041Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670019ce8bbb02df023f3c8b"
        },
        "c_name": "Orange Abstrct Printed One Shoulder Belted Cotton Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059854133-1.jpg",
                "_id": {
                    "$oid": "670019ce8bbb02df023f3c8c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059854133-2.jpg",
                "_id": {
                    "$oid": "670019ce8bbb02df023f3c8d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059854133-3.jpg",
                "_id": {
                    "$oid": "670019ce8bbb02df023f3c8e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059854133-4.jpg",
                "_id": {
                    "$oid": "670019ce8bbb02df023f3c8f"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 839,
        "c_description": "Orange & green abstract printed basic jumpsuit ,has a one shoulde, sleeveless, 1 pockets with sequinned belt attached",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:37:34.326Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:37:34.326Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670019f58bbb02df023f3c92"
        },
        "c_name": "V-Neck Printed Waist Tie-Ups Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059893193-1.jpg",
                "_id": {
                    "$oid": "670019f58bbb02df023f3c93"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059893193-2.jpg",
                "_id": {
                    "$oid": "670019f58bbb02df023f3c94"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059893193-3.jpg",
                "_id": {
                    "$oid": "670019f58bbb02df023f3c95"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059893193-4.jpg",
                "_id": {
                    "$oid": "670019f58bbb02df023f3c96"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 879,
        "c_description": "Black and red printed basic jumpsuit ,has a v-neck, long sleeves, 2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:38:13.404Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:38:13.404Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001a308bbb02df023f3c99"
        },
        "c_name": "White Floral Print Playsuit With Ruffles",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059952554-1.jpg",
                "_id": {
                    "$oid": "67001a308bbb02df023f3c9a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059952554-2.jpg",
                "_id": {
                    "$oid": "67001a308bbb02df023f3c9b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059952554-3.jpg",
                "_id": {
                    "$oid": "67001a308bbb02df023f3c9c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059952554-4.jpg",
                "_id": {
                    "$oid": "67001a308bbb02df023f3c9d"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 559,
        "c_description": "White and pink floral printed playsuit with ruffles ,has a square neck, sleeveless, 2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:39:12.771Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:39:12.771Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001a4d8bbb02df023f3ca0"
        },
        "c_name": "Pink Shirt Collar Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059981025-1.jpg",
                "_id": {
                    "$oid": "67001a4d8bbb02df023f3ca1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059981025-2.jpg",
                "_id": {
                    "$oid": "67001a4d8bbb02df023f3ca2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059981025-3.jpg",
                "_id": {
                    "$oid": "67001a4d8bbb02df023f3ca3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728059981025-4.jpg",
                "_id": {
                    "$oid": "67001a4d8bbb02df023f3ca4"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1042,
        "c_description": "Pink solid basic jumpsuit ,has a shirt collar, sleeveless, 2 pockets",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:39:41.277Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:39:41.277Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001ab28bbb02df023f3cab"
        },
        "c_name": "Maroon & Black Lace Up Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060082649-1.jpg",
                "_id": {
                    "$oid": "67001ab28bbb02df023f3cac"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060082649-2.jpg",
                "_id": {
                    "$oid": "67001ab28bbb02df023f3cad"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060082649-3.jpg",
                "_id": {
                    "$oid": "67001ab28bbb02df023f3cae"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060082649-4.jpg",
                "_id": {
                    "$oid": "67001ab28bbb02df023f3caf"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 1200,
        "c_description": "Maroon and black solid basic jumpsuit with lace up detail ,has a round neck, short sleeves, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:41:22.960Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:41:22.960Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001b098bbb02df023f3cb2"
        },
        "c_name": "Red & Black Colourblocked Basic Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060168645-1.jpg",
                "_id": {
                    "$oid": "67001b098bbb02df023f3cb3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060168645-2.jpg",
                "_id": {
                    "$oid": "67001b098bbb02df023f3cb4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060168645-3.jpg",
                "_id": {
                    "$oid": "67001b098bbb02df023f3cb5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060168645-4.jpg",
                "_id": {
                    "$oid": "67001b098bbb02df023f3cb6"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 674,
        "c_description": "Red and black colourblocked basic jumpsuit with waist tie-ups ,has a v-neck, long sleeves, 2 pockets",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:42:49.067Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:42:49.067Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001b258bbb02df023f3cb9"
        },
        "c_name": "Strappy Belted Jumpsuit",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060197345-1.jpg",
                "_id": {
                    "$oid": "67001b258bbb02df023f3cba"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060197345-2.jpg",
                "_id": {
                    "$oid": "67001b258bbb02df023f3cbb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060197345-3.jpg",
                "_id": {
                    "$oid": "67001b258bbb02df023f3cbc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060197345-4.jpg",
                "_id": {
                    "$oid": "67001b258bbb02df023f3cbd"
                }
            }
        ],
        "c_brand": "JUMPSUITS",
        "c_price": 815,
        "c_description": "Rust solid basic jumpsuit with gathers ,has a shoulder straps, sleeveless, 2 pockets",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:43:17.575Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:43:17.575Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001b8d8bbb02df023f3cca"
        },
        "c_name": "Round Neck Fit and Flare Midi Drees",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060300857-1.jpg",
                "_id": {
                    "$oid": "67001b8d8bbb02df023f3ccb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060300857-2.jpg",
                "_id": {
                    "$oid": "67001b8d8bbb02df023f3ccc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060300857-3.jpg",
                "_id": {
                    "$oid": "67001b8d8bbb02df023f3ccd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060300857-4.jpg",
                "_id": {
                    "$oid": "67001b8d8bbb02df023f3cce"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Navy blue fit & flare dress\nSolid\nRound neck\nThree quarter regular sleeves\nMidi with flared hem\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:45:01.092Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:45:01.092Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001ba68bbb02df023f3cd1"
        },
        "c_name": "The Lifestyle Co. Self Design Schiffli Choker Neck Cotton A-Line Mini Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060326508-1.jpg",
                "_id": {
                    "$oid": "67001ba68bbb02df023f3cd2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060326508-2.jpg",
                "_id": {
                    "$oid": "67001ba68bbb02df023f3cd3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060326508-3.jpg",
                "_id": {
                    "$oid": "67001ba68bbb02df023f3cd4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060326508-4.jpg",
                "_id": {
                    "$oid": "67001ba68bbb02df023f3cd5"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1183,
        "c_description": "Cream Dress\nSelf design\nA-line Shaped\nChoker neck\nThree-quarter puff sleeves\nFlared hem\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:45:26.826Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:45:26.826Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001bc18bbb02df023f3cd8"
        },
        "c_name": "Ethnic Motifs Printed Mandarin Collar Ethnic Maxi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060352814-1.jpg",
                "_id": {
                    "$oid": "67001bc18bbb02df023f3cd9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060352814-2.jpg",
                "_id": {
                    "$oid": "67001bc18bbb02df023f3cda"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060352814-3.jpg",
                "_id": {
                    "$oid": "67001bc18bbb02df023f3cdb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060352814-4.jpg",
                "_id": {
                    "$oid": "67001bc18bbb02df023f3cdc"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Black and red ethnic motifs printed maxi ethnic dress\nMandarin collar \nThree quarter, regular sleeves\nMaxi length with flared hem\nMachine weave regular viscose rayon",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:45:53.046Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:45:53.046Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001bdb8bbb02df023f3cdf"
        },
        "c_name": "Abstract Printed Keyhole Neck Fit And Flare Midi Dress",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060378888-1.jpg",
                "_id": {
                    "$oid": "67001bdb8bbb02df023f3ce0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060378888-2.jpg",
                "_id": {
                    "$oid": "67001bdb8bbb02df023f3ce1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060378888-3.jpg",
                "_id": {
                    "$oid": "67001bdb8bbb02df023f3ce2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060378888-4.jpg",
                "_id": {
                    "$oid": "67001bdb8bbb02df023f3ce3"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 2549,
        "c_description": "Green & cream abstract print fit & flare dress\nKeyhole neck\nShort, flared sleeves\nMidi length with flared hem\nWoven Poly georgette fabric\nButton closure\nComes with a belt",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:46:19.144Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:46:19.144Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001bf98bbb02df023f3ce6"
        },
        "c_name": "Floral Print cape sleeves fit and falre cotton drees",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060409689-1.jpg",
                "_id": {
                    "$oid": "67001bf98bbb02df023f3ce7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060409689-2.jpg",
                "_id": {
                    "$oid": "67001bf98bbb02df023f3ce8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060409689-3.jpg",
                "_id": {
                    "$oid": "67001bf98bbb02df023f3ce9"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1199,
        "c_description": "Green floral print a-line dress\nRound neck\nThree-quarter, cape sleeve\nbelted\nKnee length length in straight hem\nKnitted fabric\nComes with a belt",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:46:49.895Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:46:49.895Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001c358bbb02df023f3cec"
        },
        "c_name": "V-Neck Striped Fit and Flare Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060469397-1.jpg",
                "_id": {
                    "$oid": "67001c358bbb02df023f3ced"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060469397-2.jpg",
                "_id": {
                    "$oid": "67001c358bbb02df023f3cee"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060469397-3.jpg",
                "_id": {
                    "$oid": "67001c358bbb02df023f3cef"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060469397-4.jpg",
                "_id": {
                    "$oid": "67001c358bbb02df023f3cf0"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 975,
        "c_description": "Teal solid self design wrap dress dress\nV-neck\nThree-quarter, cuffed sleeves\nMidi length in flared hem\nGeorgette fabric",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:47:49.573Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:47:49.573Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001c5d8bbb02df023f3cf3"
        },
        "c_name": "Striped Shirt Collar A-Line Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060508661-1.jpg",
                "_id": {
                    "$oid": "67001c5d8bbb02df023f3cf4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060508661-2.jpg",
                "_id": {
                    "$oid": "67001c5d8bbb02df023f3cf5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060508661-3.jpg",
                "_id": {
                    "$oid": "67001c5d8bbb02df023f3cf6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060508661-4.jpg",
                "_id": {
                    "$oid": "67001c5d8bbb02df023f3cf7"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1165,
        "c_description": "Fuchsia, white dress\nStriped\nA-line shape\nShirt collar \nThree-quarter regular sleeves\nFlared hem\nKnee length",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:48:29.388Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:48:29.388Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001c798bbb02df023f3cfa"
        },
        "c_name": "Floral Print Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060537015-1.jpg",
                "_id": {
                    "$oid": "67001c798bbb02df023f3cfb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060537015-2.jpg",
                "_id": {
                    "$oid": "67001c798bbb02df023f3cfc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060537015-3.jpg",
                "_id": {
                    "$oid": "67001c798bbb02df023f3cfd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060537015-4.jpg",
                "_id": {
                    "$oid": "67001c798bbb02df023f3cfe"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1176,
        "c_description": "Cream-coloured regular a-line Dress\nFloral print\nRound neck, three-quarter, regular sleeves \nWoven polyester",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:48:57.251Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:48:57.251Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001c9e8bbb02df023f3d01"
        },
        "c_name": "Striped Midi Fit and Flare Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060573853-1.jpg",
                "_id": {
                    "$oid": "67001c9e8bbb02df023f3d02"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060573853-2.jpg",
                "_id": {
                    "$oid": "67001c9e8bbb02df023f3d03"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060573853-3.jpg",
                "_id": {
                    "$oid": "67001c9e8bbb02df023f3d04"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060573853-4.jpg",
                "_id": {
                    "$oid": "67001c9e8bbb02df023f3d05"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 705,
        "c_description": "Navy blue and white striped middi dress\nRound neck\nShort, puf sleeve\nMiddi length in flared hem",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:49:34.142Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:49:34.142Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001cb88bbb02df023f3d08"
        },
        "c_name": "The Lifestyle Co Printed Short Sleeve Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060600194-1.jpg",
                "_id": {
                    "$oid": "67001cb88bbb02df023f3d09"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060600194-2.jpg",
                "_id": {
                    "$oid": "67001cb88bbb02df023f3d0a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060600194-3.jpg",
                "_id": {
                    "$oid": "67001cb88bbb02df023f3d0b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060600194-4.jpg",
                "_id": {
                    "$oid": "67001cb88bbb02df023f3d0c"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 909,
        "c_description": "Navy blue & white Dress\nFloral Printed\nFit and Flared Shaped\nHas a waist belt\nV- neck\nshort regular sleeves\nStraight hem\nSlip-on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:50:00.447Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:50:00.447Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001cda8bbb02df023f3d0f"
        },
        "c_name": "Printed Tunic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060634186-1.jpg",
                "_id": {
                    "$oid": "67001cda8bbb02df023f3d10"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060634186-2.jpg",
                "_id": {
                    "$oid": "67001cda8bbb02df023f3d11"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060634186-3.jpg",
                "_id": {
                    "$oid": "67001cda8bbb02df023f3d12"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060634186-4.jpg",
                "_id": {
                    "$oid": "67001cda8bbb02df023f3d13"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1499,
        "c_description": "Knee-length tunic dress in an airy viscose weave with a band collar and a V-shaped opening at the front. Loose fit with gently dropped shoulders, short sleeves and a straight-cut hem with a slit at each side. Slightly longer at the back. Unlined.",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:50:34.475Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:50:34.475Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001d018bbb02df023f3d16"
        },
        "c_name": "The Lifestyle Co Printed A-Line Mini Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060673660-1.jpg",
                "_id": {
                    "$oid": "67001d018bbb02df023f3d17"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060673660-2.jpg",
                "_id": {
                    "$oid": "67001d018bbb02df023f3d18"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060673660-3.jpg",
                "_id": {
                    "$oid": "67001d018bbb02df023f3d19"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060673660-4.jpg",
                "_id": {
                    "$oid": "67001d018bbb02df023f3d1a"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 839,
        "c_description": "Blue Dress\nPrinted\nA-Line Shaped\nMini\nV-Neck\nShort Sleeves\nFlounce hem\nSlip-on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:51:13.863Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:51:13.863Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001d2e8bbb02df023f3d1d"
        },
        "c_name": "Solid Fit and Flare Maxi Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060718219-1.jpg",
                "_id": {
                    "$oid": "67001d2e8bbb02df023f3d1e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060718219-2.jpg",
                "_id": {
                    "$oid": "67001d2e8bbb02df023f3d1f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060718219-3.jpg",
                "_id": {
                    "$oid": "67001d2e8bbb02df023f3d20"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060718219-4.jpg",
                "_id": {
                    "$oid": "67001d2e8bbb02df023f3d21"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 674,
        "c_description": "Brown solid fit & flare dress\nV-neck\nLong, regular sleeves\nMaxi length in flared hem\nCrepe fabric",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:51:58.438Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:51:58.438Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001d538bbb02df023f3d24"
        },
        "c_name": "Ethnic Motifs Printed Fit and Flare Pleated Maternity Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060755253-1.jpg",
                "_id": {
                    "$oid": "67001d538bbb02df023f3d25"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060755253-2.jpg",
                "_id": {
                    "$oid": "67001d538bbb02df023f3d26"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060755253-3.jpg",
                "_id": {
                    "$oid": "67001d538bbb02df023f3d27"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060755253-4.jpg",
                "_id": {
                    "$oid": "67001d538bbb02df023f3d28"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 839,
        "c_description": "Colour: grey & blue\nEthnic motifs printed\nRound neck\nThree-quarter, regular sleeves\nFit & flare shape pleated style\nThread work detail\nMidi length with flared hem\nMachine weave regular blended\nComes with belt",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:52:35.497Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:52:35.497Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001d808bbb02df023f3d2b"
        },
        "c_name": "Striped Fit and Flare Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060800139-1.jpg",
                "_id": {
                    "$oid": "67001d808bbb02df023f3d2c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060800139-2.jpg",
                "_id": {
                    "$oid": "67001d808bbb02df023f3d2d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060800139-3.jpg",
                "_id": {
                    "$oid": "67001d808bbb02df023f3d2e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060800139-4.jpg",
                "_id": {
                    "$oid": "67001d808bbb02df023f3d2f"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 975,
        "c_description": "Green self design wrap dress dress\nV-neck\nThree-quarter, regular sleeves\nMidi length in flared hem\nGeorgette fabric",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:53:20.378Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:53:20.378Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001dbf8bbb02df023f3d32"
        },
        "c_name": "Tie-Detail Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060863391-1.jpg",
                "_id": {
                    "$oid": "67001dbf8bbb02df023f3d33"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060863391-2.jpg",
                "_id": {
                    "$oid": "67001dbf8bbb02df023f3d34"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060863391-3.jpg",
                "_id": {
                    "$oid": "67001dbf8bbb02df023f3d35"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060863391-4.jpg",
                "_id": {
                    "$oid": "67001dbf8bbb02df023f3d36"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1499,
        "c_description": "Short, loose-fit dress in woven fabric with a round neckline and a V-shaped opening with spaghetti ties at the front. Long, raglan balloon sleeves with a narrow trim and button at the cuffs. Gathers around the neckline and a gathered seam above the hem for added volume. Unlined.",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:54:23.650Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:54:23.650Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001df18bbb02df023f3d39"
        },
        "c_name": "Checked Blazer Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060913063-1.jpg",
                "_id": {
                    "$oid": "67001df18bbb02df023f3d3a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060913063-2.jpg",
                "_id": {
                    "$oid": "67001df18bbb02df023f3d3b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060913063-3.jpg",
                "_id": {
                    "$oid": "67001df18bbb02df023f3d3c"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 999,
        "c_description": "white & black checked Blazer Dresses\nCuban Collar\nLong, cuffed sleeves\nAbove knee length in curved hem\nCotton fabric\nButton closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:55:13.262Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:55:13.262Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001e308bbb02df023f3d3f"
        },
        "c_name": "The Lifestyle Co Off Shoulder Mini Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060976649-1.jpg",
                "_id": {
                    "$oid": "67001e308bbb02df023f3d40"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060976649-2.jpg",
                "_id": {
                    "$oid": "67001e308bbb02df023f3d41"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060976649-3.jpg",
                "_id": {
                    "$oid": "67001e308bbb02df023f3d42"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728060976649-4.jpg",
                "_id": {
                    "$oid": "67001e308bbb02df023f3d43"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 877,
        "c_description": "Peach Dress\nFloral Printed\nFit & Flare Shaped\nOff Shoulder\nLong sleeves\nStraight hem\nSlip-on closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:56:16.927Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:56:16.927Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001e6e8bbb02df023f3d46"
        },
        "c_name": "Geometric Print Flared Sleeve Fit & flare Ruffles Maxi Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061037829-1.jpg",
                "_id": {
                    "$oid": "67001e6e8bbb02df023f3d47"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061037829-2.jpg",
                "_id": {
                    "$oid": "67001e6e8bbb02df023f3d48"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061037829-3.jpg",
                "_id": {
                    "$oid": "67001e6e8bbb02df023f3d49"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061037829-4.jpg",
                "_id": {
                    "$oid": "67001e6e8bbb02df023f3d4a"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 599,
        "c_description": "Colour: maroon\nGeometric printed\nRound neck\nShort, flared sleeves\nFit&flare shape with regular style\nMaxi length with flared hem\nMachine weave regular viscose rayon",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:57:18.133Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:57:18.133Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001e898bbb02df023f3d4d"
        },
        "c_name": "Printed Fit and Flare Smocked Midi Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061065356-1.jpg",
                "_id": {
                    "$oid": "67001e898bbb02df023f3d4e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061065356-2.jpg",
                "_id": {
                    "$oid": "67001e898bbb02df023f3d4f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061065356-3.jpg",
                "_id": {
                    "$oid": "67001e898bbb02df023f3d50"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061065356-4.jpg",
                "_id": {
                    "$oid": "67001e898bbb02df023f3d51"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Blue solid print fit & flare dress\nSquare neck\nShort, puff sleeve\nApplique detail\nKnee length length in flared hem\nCotton fabric",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:57:45.562Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:57:45.562Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001ea08bbb02df023f3d54"
        },
        "c_name": "Maternity Feeding Fit & Flare Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061088539-1.jpg",
                "_id": {
                    "$oid": "67001ea08bbb02df023f3d55"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061088539-2.jpg",
                "_id": {
                    "$oid": "67001ea08bbb02df023f3d56"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061088539-3.jpg",
                "_id": {
                    "$oid": "67001ea08bbb02df023f3d57"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061088539-4.jpg",
                "_id": {
                    "$oid": "67001ea08bbb02df023f3d58"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1049,
        "c_description": "Blue & white printed maternity feeding fit and flare Dress\nV- Neck\nShort,puff sleeves\nMidi  in flared hem\nConcealed zip closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:58:08.766Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:58:08.766Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001ee38bbb02df023f3d60"
        },
        "c_name": "Embroidered Round Neck Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061155650-1.jpg",
                "_id": {
                    "$oid": "67001ee38bbb02df023f3d61"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061155650-2.jpg",
                "_id": {
                    "$oid": "67001ee38bbb02df023f3d62"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061155650-3.jpg",
                "_id": {
                    "$oid": "67001ee38bbb02df023f3d63"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061155650-4.jpg",
                "_id": {
                    "$oid": "67001ee38bbb02df023f3d64"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 839,
        "c_description": "Blue dress, Embroidered, Fit & flare shaped, Has a lining, Round Neck, Short regular sleeves, flared hem and slip-on closure.",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T16:59:15.949Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T16:59:15.949Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001f208bbb02df023f3d6c"
        },
        "c_name": "Women Fit & Flare Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061216540-1.jpg",
                "_id": {
                    "$oid": "67001f208bbb02df023f3d6d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061216540-2.jpg",
                "_id": {
                    "$oid": "67001f208bbb02df023f3d6e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061216540-3.jpg",
                "_id": {
                    "$oid": "67001f208bbb02df023f3d6f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061216540-4.jpg",
                "_id": {
                    "$oid": "67001f208bbb02df023f3d70"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Blue abstract solid fit & flare dress\nSquare neck\nShort, regular sleeves\nGathered or pleated detail\nMaxi length in curved hem\nCotton fabric",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:00:16.838Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:00:16.838Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001f3f8bbb02df023f3d73"
        },
        "c_name": "Women Printed Cotton Short Kaftan Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061246858-1.jpg",
                "_id": {
                    "$oid": "67001f3f8bbb02df023f3d74"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061246858-2.jpg",
                "_id": {
                    "$oid": "67001f3f8bbb02df023f3d75"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061246858-3.jpg",
                "_id": {
                    "$oid": "67001f3f8bbb02df023f3d76"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061246858-4.jpg",
                "_id": {
                    "$oid": "67001f3f8bbb02df023f3d77"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 699,
        "c_description": "Blue abstract print kaftan dress\nV-neck\nShort, flared sleeve\nAbove knee length in asymmetric hem",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:00:47.150Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:00:47.150Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001f8d8bbb02df023f3d7a"
        },
        "c_name": "Striped Fit and Flare Pleated Maternity Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061325196-1.jpg",
                "_id": {
                    "$oid": "67001f8d8bbb02df023f3d7b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061325196-2.jpg",
                "_id": {
                    "$oid": "67001f8d8bbb02df023f3d7c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061325196-3.jpg",
                "_id": {
                    "$oid": "67001f8d8bbb02df023f3d7d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061325196-4.jpg",
                "_id": {
                    "$oid": "67001f8d8bbb02df023f3d7e"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 782,
        "c_description": "Colour: Grey\nStriped\nRound neck\nThree-quarter, Regular sleeves\nFit& flare shape with Pleated style\nCalf length with flared hem\nMachine weave regular blended",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:02:05.449Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:02:05.449Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001fc98bbb02df023f3d81"
        },
        "c_name": "Women Printed Sweetheart Neck Bell Sleeves Ethnic Maxi Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061385389-1.jpg",
                "_id": {
                    "$oid": "67001fc98bbb02df023f3d82"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061385389-2.jpg",
                "_id": {
                    "$oid": "67001fc98bbb02df023f3d83"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061385389-3.jpg",
                "_id": {
                    "$oid": "67001fc98bbb02df023f3d84"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1379,
        "c_description": "Black and grey ethnic Dress\nPrinted\nGown Shaped\nSweetheart- neck\nThree-Quarter Sleeves\nFlared hem\nConcealed Zip",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:03:05.615Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:03:05.615Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67001ffd8bbb02df023f3d87"
        },
        "c_name": "Georgette Solid Maxi Dresses With Cold-Shoulder Sleeves",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061437316-1.jpg",
                "_id": {
                    "$oid": "67001ffd8bbb02df023f3d88"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061437316-2.jpg",
                "_id": {
                    "$oid": "67001ffd8bbb02df023f3d89"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061437316-3.jpg",
                "_id": {
                    "$oid": "67001ffd8bbb02df023f3d8a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061437316-4.jpg",
                "_id": {
                    "$oid": "67001ffd8bbb02df023f3d8b"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Black solid fit & flare dress\nRound neck\nShort, bell sleeve\nApplique detail\nMaxi length in flared hem\nGeorgette fabric\nZip closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:03:57.534Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:03:57.534Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670020408bbb02df023f3d93"
        },
        "c_name": "The Lifestyle Co Women Printed Above Knee Round Neck Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061504681-1.jpg",
                "_id": {
                    "$oid": "670020408bbb02df023f3d94"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061504681-2.jpg",
                "_id": {
                    "$oid": "670020408bbb02df023f3d95"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061504681-3.jpg",
                "_id": {
                    "$oid": "670020408bbb02df023f3d96"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061504681-4.jpg",
                "_id": {
                    "$oid": "670020408bbb02df023f3d97"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 888,
        "c_description": "Black & purple dress\nPrinted\nFit and Flared Shaped\nAbove Knee-length\nRound Neck\nShort Sleeves\nFlared hem\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:05:04.917Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:05:04.917Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700205c8bbb02df023f3d9a"
        },
        "c_name": "Georgette Printed Striped Fit and Flare Dresses Comes with a belt",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061531751-1.jpg",
                "_id": {
                    "$oid": "6700205c8bbb02df023f3d9b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061531751-2.jpg",
                "_id": {
                    "$oid": "6700205c8bbb02df023f3d9c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061531751-3.jpg",
                "_id": {
                    "$oid": "6700205c8bbb02df023f3d9d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061531751-4.jpg",
                "_id": {
                    "$oid": "6700205c8bbb02df023f3d9e"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Magenta striped print fit & flare dress\nShirt collar\nThree-quarter, puff sleeve\nApplique detail\nMaxi length in flared hem\nGeorgette fabric\nComes with a belt",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:05:32.025Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:05:32.025Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670020788bbb02df023f3da1"
        },
        "c_name": "Georgette Solid Midi Fit and Flare Dresses With Gathered or Pleated",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061560535-1.jpg",
                "_id": {
                    "$oid": "670020788bbb02df023f3da2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061560535-2.jpg",
                "_id": {
                    "$oid": "670020788bbb02df023f3da3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061560535-3.jpg",
                "_id": {
                    "$oid": "670020788bbb02df023f3da4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061560535-4.jpg",
                "_id": {
                    "$oid": "670020788bbb02df023f3da5"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Black solid fit & flare dress\nSquare neck\nShort, puff sleeve\nApplique detail\nMaxi length in flared hem\nGeorgette fabric",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:06:00.948Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:06:00.948Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670020a08bbb02df023f3da8"
        },
        "c_name": "Floral Printed Puff Sleeves Tiered Gown Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061600714-1.jpg",
                "_id": {
                    "$oid": "670020a08bbb02df023f3da9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061600714-2.jpg",
                "_id": {
                    "$oid": "670020a08bbb02df023f3daa"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061600714-3.jpg",
                "_id": {
                    "$oid": "670020a08bbb02df023f3dab"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1149,
        "c_description": "Turquoise blue ethnic Dress\nFloral Printed\nGown Shaped\nSweatheart neck\nShort, puff sleeves\nMaxi length with flared hem\nSlip-on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:06:40.962Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:06:40.962Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670020ba8bbb02df023f3dae"
        },
        "c_name": "Women Embroidered A-Line Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061626750-1.jpg",
                "_id": {
                    "$oid": "670020ba8bbb02df023f3daf"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061626750-2.jpg",
                "_id": {
                    "$oid": "670020ba8bbb02df023f3db0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061626750-3.jpg",
                "_id": {
                    "$oid": "670020ba8bbb02df023f3db1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061626750-4.jpg",
                "_id": {
                    "$oid": "670020ba8bbb02df023f3db2"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 2331,
        "c_description": "Maroon ethnic Dress\nEmbroidered\nA-Line Shaped\nRound- neck\nFull Sleeves\nFlared hem\nSlip on with back tie-up details",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:07:06.969Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:07:06.969Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670020d88bbb02df023f3db5"
        },
        "c_name": "Shoulder Straps Maxi Ethnic Dresses with Floral Embroidered Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061655781-1.jpg",
                "_id": {
                    "$oid": "670020d88bbb02df023f3db6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061655781-2.jpg",
                "_id": {
                    "$oid": "670020d88bbb02df023f3db7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061655781-3.jpg",
                "_id": {
                    "$oid": "670020d88bbb02df023f3db8"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 2558,
        "c_description": "Mauve ethnic dress\nSolid\nMaxi Shaped\nShoulder straps\nSleeveless, no sleeves\nFlared hem\nTie-up closure\nJacket details:\nMauve, yellow & red\nFloral embroidered\nLong, regular sleeves",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:07:36.009Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:07:36.009Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670020f08bbb02df023f3dbb"
        },
        "c_name": "Navy Blue Abstract Printed A-Line Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061680468-1.jpg",
                "_id": {
                    "$oid": "670020f08bbb02df023f3dbc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061680468-2.jpg",
                "_id": {
                    "$oid": "670020f08bbb02df023f3dbd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061680468-3.jpg",
                "_id": {
                    "$oid": "670020f08bbb02df023f3dbe"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061680468-4.jpg",
                "_id": {
                    "$oid": "670020f08bbb02df023f3dbf"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 857,
        "c_description": "Navy Blue ethnic Dress\nAbstract printed\nA-Line Shaped\nMandarin collar \nThree Quarter regular sleeves\nFlared hem\nSlip on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:08:00.750Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:08:00.750Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670021428bbb02df023f3dc2"
        },
        "c_name": "Floral Printed V-Neck Fit & Flare Georgette Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061762309-1.jpg",
                "_id": {
                    "$oid": "670021428bbb02df023f3dc3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061762309-2.jpg",
                "_id": {
                    "$oid": "670021428bbb02df023f3dc4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061762309-3.jpg",
                "_id": {
                    "$oid": "670021428bbb02df023f3dc5"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 949,
        "c_description": "Pink ethnic Dress\nFloral Printed\nFit and Flared Shaped\nV neck\nThree-quarter,bell sleeves\nFlared hem\nSlip-on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:09:22.560Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:09:22.560Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670021858bbb02df023f3dc8"
        },
        "c_name": "Embroidered maxi Ethnic motifs Georgette Fit and Flare Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061828763-1.jpg",
                "_id": {
                    "$oid": "670021858bbb02df023f3dc9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061828763-2.jpg",
                "_id": {
                    "$oid": "670021858bbb02df023f3dca"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061828763-3.jpg",
                "_id": {
                    "$oid": "670021858bbb02df023f3dcb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061828763-4.jpg",
                "_id": {
                    "$oid": "670021858bbb02df023f3dcc"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1749,
        "c_description": "Pink ethnic Dress\nPrinted\nfit and flare\nRound- Neck\nThree Quarter regular sleeves\nFlared hem\nSlip on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:10:29.066Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:10:29.066Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670021c28bbb02df023f3dd3"
        },
        "c_name": "Womens Embroidered Shirt Collar Anarkali Ethnic Maxi Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061889037-1.jpg",
                "_id": {
                    "$oid": "670021c28bbb02df023f3dd4"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061889037-2.jpg",
                "_id": {
                    "$oid": "670021c28bbb02df023f3dd5"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061889037-3.jpg",
                "_id": {
                    "$oid": "670021c28bbb02df023f3dd6"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061889037-4.jpg",
                "_id": {
                    "$oid": "670021c28bbb02df023f3dd7"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 899,
        "c_description": "Purple ethnic Dress\nEmbroidered and sequined details\nAnarkali Shaped\nShirt neck\nThree quarter regular Sleeves\nFlared hem\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:11:30.304Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:11:30.304Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670021cd8bbb02df023f3dda"
        },
        "c_name": "Maxi Length Gown With Add-On Sleeves Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061899221-1.jpg",
                "_id": {
                    "$oid": "670021cd8bbb02df023f3ddb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061899221-2.jpg",
                "_id": {
                    "$oid": "670021cd8bbb02df023f3ddc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061899221-3.jpg",
                "_id": {
                    "$oid": "670021cd8bbb02df023f3ddd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061899221-4.jpg",
                "_id": {
                    "$oid": "670021cd8bbb02df023f3dde"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1764,
        "c_description": "Teal green ethnic dress\nSolid\nFit and flared shaped\nHas a add-on sleeves\nSquare neck\nThree-quarter cap sleeves\nFlared hem\nConcealed zip closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:11:41.293Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:11:41.293Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670022158bbb02df023f3de6"
        },
        "c_name": "Floral Printed Shoulder Straps Gathered or Pleated Midi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061973215-1.jpg",
                "_id": {
                    "$oid": "670022158bbb02df023f3de7"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061973215-2.jpg",
                "_id": {
                    "$oid": "670022158bbb02df023f3de8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061973215-3.jpg",
                "_id": {
                    "$oid": "670022158bbb02df023f3de9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728061973215-4.jpg",
                "_id": {
                    "$oid": "670022158bbb02df023f3dea"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1574,
        "c_description": "Green ethnic Dress\nFloral Printed\nFit and flare Shaped\nshoulder strap neck\nSleeveless\nFlared hem\n\nEthnic Dresses",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:12:53.557Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:12:53.557Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670022fca235b66b583e6936"
        },
        "c_name": "Embellished Cotton Jacquard Tiered Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062203973-1.jpg",
                "_id": {
                    "$oid": "670022fca235b66b583e6937"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062203973-2.jpg",
                "_id": {
                    "$oid": "670022fca235b66b583e6938"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062203973-3.jpg",
                "_id": {
                    "$oid": "670022fca235b66b583e6939"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062203973-4.jpg",
                "_id": {
                    "$oid": "670022fca235b66b583e693a"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 4449,
        "c_description": "Red ethnic tiered Dress With Dupatta\nEmbellished\nFit and Flared Shaped\nHas a waist belt\nV- neck\nThree-quarter regular sleeves\nFlared hem\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:16:44.482Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:16:44.482Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700232ea235b66b583e693d"
        },
        "c_name": "Floral Printed Silk Flared Ethnic Dresses Comes with a belt",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062253454-1.jpg",
                "_id": {
                    "$oid": "6700232ea235b66b583e693e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062253454-2.jpg",
                "_id": {
                    "$oid": "6700232ea235b66b583e693f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062253454-3.jpg",
                "_id": {
                    "$oid": "6700232ea235b66b583e6940"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062253454-4.jpg",
                "_id": {
                    "$oid": "6700232ea235b66b583e6941"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1949,
        "c_description": "Yellow ethnic Dress\nFloral Printed\nFit and Flared Shaped\nHas a waist belt\nRound neck\nThree-quarter regular sleeves\nFlared hem\nSlip-on closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:17:34.803Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:17:34.803Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "67002360a235b66b583e6944"
        },
        "c_name": "Tie & Dye V-Neck Fit and Flare Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062304547-1.jpg",
                "_id": {
                    "$oid": "67002360a235b66b583e6945"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062304547-2.jpg",
                "_id": {
                    "$oid": "67002360a235b66b583e6946"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062304547-3.jpg",
                "_id": {
                    "$oid": "67002360a235b66b583e6947"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062304547-4.jpg",
                "_id": {
                    "$oid": "67002360a235b66b583e6948"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1149,
        "c_description": "Blue & pink ethnic dress\nTie & dye \nFit and flared shaped\nV-neck\nLong regular sleeves\nFlared hem\nSlip-on closure",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:18:24.824Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:18:24.824Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670023a1a235b66b583e694b"
        },
        "c_name": "Ethnic Motifs Printed V-Neck Pleated Fit & flare Pure Cotton Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062369086-1.jpg",
                "_id": {
                    "$oid": "670023a1a235b66b583e694c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062369086-2.jpg",
                "_id": {
                    "$oid": "670023a1a235b66b583e694d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062369086-3.jpg",
                "_id": {
                    "$oid": "670023a1a235b66b583e694e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062369086-4.jpg",
                "_id": {
                    "$oid": "670023a1a235b66b583e694f"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1084,
        "c_description": "Blue & maroon\nEthnic Printed\nV- neck\nShort,regular sleeves\nFit and Flared \nFlared hem",
        "c_event": "New Launch",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:19:29.365Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:19:29.365Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670023b0a235b66b583e6952"
        },
        "c_name": "Floral Printed Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062383971-1.jpg",
                "_id": {
                    "$oid": "670023b0a235b66b583e6953"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062383971-2.jpg",
                "_id": {
                    "$oid": "670023b0a235b66b583e6954"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062383971-3.jpg",
                "_id": {
                    "$oid": "670023b0a235b66b583e6955"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062383971-4.jpg",
                "_id": {
                    "$oid": "670023b0a235b66b583e6956"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1599,
        "c_description": "Blue and pink ethnic Dress\nFloral Printed\nA-Line Shaped\nRound- neck\nShort regular sleeves\nFlared hem\nSlip-on closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:19:44.752Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:19:44.752Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670023e0a235b66b583e6959"
        },
        "c_name": "Sequence Embroidered Flared Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062432340-1.jpg",
                "_id": {
                    "$oid": "670023e0a235b66b583e695a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062432340-2.jpg",
                "_id": {
                    "$oid": "670023e0a235b66b583e695b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062432340-3.jpg",
                "_id": {
                    "$oid": "670023e0a235b66b583e695c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062432340-4.jpg",
                "_id": {
                    "$oid": "670023e0a235b66b583e695d"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1585,
        "c_description": "Black maxi ethnic dress\nEmbroidered & sequenced\nA-line shaped\nShoulder strap neck\nNo sleeves\nFlared hem\nSlip-on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:20:32.590Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:20:32.590Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670023eaa235b66b583e6960"
        },
        "c_name": "Embroidered Square Neck Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062442654-1.jpg",
                "_id": {
                    "$oid": "670023eaa235b66b583e6961"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062442654-2.jpg",
                "_id": {
                    "$oid": "670023eaa235b66b583e6962"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062442654-3.jpg",
                "_id": {
                    "$oid": "670023eaa235b66b583e6963"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062442654-4.jpg",
                "_id": {
                    "$oid": "670023eaa235b66b583e6964"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1745,
        "c_description": "Magenta colored ethnic Dress\nEmbroidered\nMaxi Shaped\nSquare neck\nLong regular sleeves\nFlared hem\nSlip-on closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:20:42.940Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:20:42.940Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700244da235b66b583e6967"
        },
        "c_name": "Floral Printed Round Neck Maxi Gown Ethnic Dresses With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062540887-1.jpg",
                "_id": {
                    "$oid": "6700244da235b66b583e6968"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062540887-2.jpg",
                "_id": {
                    "$oid": "6700244da235b66b583e6969"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062540887-3.jpg",
                "_id": {
                    "$oid": "6700244da235b66b583e696a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062540887-4.jpg",
                "_id": {
                    "$oid": "6700244da235b66b583e696b"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1143,
        "c_description": "Black ethnic dress  \nFloral printed\nFit and flared shaped\nHas a dupatta\nRound neck\nLong regular sleeves\nFlared hem\nSlip-on closure\nComes with duaptta ",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:22:21.168Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:22:21.168Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700244fa235b66b583e696e"
        },
        "c_name": "Printed-Length Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062543042-1.jpg",
                "_id": {
                    "$oid": "6700244fa235b66b583e696f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062543042-2.jpg",
                "_id": {
                    "$oid": "6700244fa235b66b583e6970"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062543042-3.jpg",
                "_id": {
                    "$oid": "6700244fa235b66b583e6971"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062543042-4.jpg",
                "_id": {
                    "$oid": "6700244fa235b66b583e6972"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 965,
        "c_description": "Blue ethnic Dress\nStriped\nA-line Shaped\nRound neck\nThree-quarter regular sleeves\nFlared hem",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:22:23.192Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:22:23.192Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670025e73e06b6b0ab69845a"
        },
        "c_name": "Round Neck Ethnic Dresses With Lehriya Jacket",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062951276-1.jpg",
                "_id": {
                    "$oid": "670025e73e06b6b0ab69845b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062951276-2.jpg",
                "_id": {
                    "$oid": "670025e73e06b6b0ab69845c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062951276-3.jpg",
                "_id": {
                    "$oid": "670025e73e06b6b0ab69845d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062951276-4.jpg",
                "_id": {
                    "$oid": "670025e73e06b6b0ab69845e"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 699,
        "c_description": "Grey solid a-line ethnic dress\nRound neckline\nThree-quarter regular sleeves\nCalf length with straight hem\nMachine weave regular polyester\nComes with a pink leheriya jacket",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:29:11.841Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:29:11.841Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026053e06b6b0ab698461"
        },
        "c_name": "Floral Printed A-line Maxi Gown Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062980822-1.jpg",
                "_id": {
                    "$oid": "670026053e06b6b0ab698462"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062980822-2.jpg",
                "_id": {
                    "$oid": "670026053e06b6b0ab698463"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062980822-3.jpg",
                "_id": {
                    "$oid": "670026053e06b6b0ab698464"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728062980822-4.jpg",
                "_id": {
                    "$oid": "670026053e06b6b0ab698465"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 2960,
        "c_description": "Lavender and pink ethnic Dress\nFloral Printed\nFit and Flared Shaped\nKeyhole- neck\nThree-quarter regular sleeves\nFlared hem\nSlip-on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:29:41.149Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:29:41.149Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026243e06b6b0ab698468"
        },
        "c_name": "Women Printed Cotton Shoulder Straps Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063012541-1.jpg",
                "_id": {
                    "$oid": "670026243e06b6b0ab698469"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063012541-2.jpg",
                "_id": {
                    "$oid": "670026243e06b6b0ab69846a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063012541-3.jpg",
                "_id": {
                    "$oid": "670026243e06b6b0ab69846b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063012541-4.jpg",
                "_id": {
                    "$oid": "670026243e06b6b0ab69846c"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1609,
        "c_description": "Blue ethnic Dress\nEmbroidered\nA-Line Shaped\nShoulder Strap\nsleeveless\nFlared hem\nSlip on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:30:12.778Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:30:12.778Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026413e06b6b0ab69846f"
        },
        "c_name": "Floral Printed Silk Gown Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063040807-1.jpg",
                "_id": {
                    "$oid": "670026413e06b6b0ab698470"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063040807-2.jpg",
                "_id": {
                    "$oid": "670026413e06b6b0ab698471"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063040807-3.jpg",
                "_id": {
                    "$oid": "670026413e06b6b0ab698472"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063040807-4.jpg",
                "_id": {
                    "$oid": "670026413e06b6b0ab698473"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 3127,
        "c_description": "Mustard yellow, green and red ethnic Dress\nFloral Printed\nGown Shaped\nV neck\nLong regular sleeves\nFlared hem\nZip closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:30:41.132Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:30:41.132Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026623e06b6b0ab698476"
        },
        "c_name": "Embroidered Midi-Length Anarkali Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063074588-1.jpg",
                "_id": {
                    "$oid": "670026623e06b6b0ab698477"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063074588-2.jpg",
                "_id": {
                    "$oid": "670026623e06b6b0ab698478"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063074588-3.jpg",
                "_id": {
                    "$oid": "670026623e06b6b0ab698479"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063074588-4.jpg",
                "_id": {
                    "$oid": "670026623e06b6b0ab69847a"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 2262,
        "c_description": "Black ethnic Dress\nembroidered\nA-line Shaped\nRound neck\nFull sleeves\nFlared hem",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:31:14.838Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:31:14.838Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026813e06b6b0ab69847d"
        },
        "c_name": "Floral Printed Bell Sleeves Tiered Cotton Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063105644-1.jpg",
                "_id": {
                    "$oid": "670026813e06b6b0ab69847e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063105644-2.jpg",
                "_id": {
                    "$oid": "670026813e06b6b0ab69847f"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063105644-3.jpg",
                "_id": {
                    "$oid": "670026813e06b6b0ab698480"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063105644-4.jpg",
                "_id": {
                    "$oid": "670026813e06b6b0ab698481"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1296,
        "c_description": "Red floral printed maxi dress\nV-neck \nThree-quarter, bell sleeves\nGathered tiered detail\nMaxi length in flared hem\nCotton fabric",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:31:45.947Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:31:45.947Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700269c3e06b6b0ab698484"
        },
        "c_name": "Embellished Maxi Gown Ethnic Dresses With Dupatta",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063132428-1.jpg",
                "_id": {
                    "$oid": "6700269c3e06b6b0ab698485"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063132428-2.jpg",
                "_id": {
                    "$oid": "6700269c3e06b6b0ab698486"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063132428-3.jpg",
                "_id": {
                    "$oid": "6700269c3e06b6b0ab698487"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063132428-4.jpg",
                "_id": {
                    "$oid": "6700269c3e06b6b0ab698488"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1999,
        "c_description": "Pink ethnic Dress\nEmbellished\nGown Shaped\nHas a Dupatta\nRound- neck\nSleeveless\nFlared hem\nSlip-on closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:32:12.701Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:32:12.701Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026bb3e06b6b0ab69848b"
        },
        "c_name": "Ehnic Motifs Printed Layered Anarkali Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063163442-1.jpg",
                "_id": {
                    "$oid": "670026bb3e06b6b0ab69848c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063163442-2.jpg",
                "_id": {
                    "$oid": "670026bb3e06b6b0ab69848d"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063163442-3.jpg",
                "_id": {
                    "$oid": "670026bb3e06b6b0ab69848e"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063163442-4.jpg",
                "_id": {
                    "$oid": "670026bb3e06b6b0ab69848f"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 724,
        "c_description": "Maroon & beige ethnic motifs printed dress, has a round neck, long sleeves, layered, flared hem",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:32:43.676Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:32:43.676Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026d63e06b6b0ab698492"
        },
        "c_name": "Embellished Sequined Detail Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063189969-1.jpg",
                "_id": {
                    "$oid": "670026d63e06b6b0ab698493"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063189969-2.jpg",
                "_id": {
                    "$oid": "670026d63e06b6b0ab698494"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063189969-3.jpg",
                "_id": {
                    "$oid": "670026d63e06b6b0ab698495"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063189969-4.jpg",
                "_id": {
                    "$oid": "670026d63e06b6b0ab698496"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1007,
        "c_description": "Black maxi ethnic Dress\nEmbellished \nMaxi Shaped\nRound neck\nThree-quarter regular sleeves\nSequined detail\nFlared hem\nSlip-on closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:33:10.285Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:33:10.285Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670026f73e06b6b0ab698499"
        },
        "c_name": "Women Printed V-Neck Puff Sleeves A-Line Asymmetric Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063223510-1.jpg",
                "_id": {
                    "$oid": "670026f73e06b6b0ab69849a"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063223510-2.jpg",
                "_id": {
                    "$oid": "670026f73e06b6b0ab69849b"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063223510-3.jpg",
                "_id": {
                    "$oid": "670026f73e06b6b0ab69849c"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063223510-4.jpg",
                "_id": {
                    "$oid": "670026f73e06b6b0ab69849d"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1439,
        "c_description": "Purple ethnic Dress\nPrinted\nA-Line Shaped\nRound Neck\nShort Puffed sleeves\nHigh-Low hem\nSlip on closure\nComes with a waist belt",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:33:43.836Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:33:43.836Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670027163e06b6b0ab6984a0"
        },
        "c_name": "Printed Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063254340-1.jpg",
                "_id": {
                    "$oid": "670027163e06b6b0ab6984a1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063254340-2.jpg",
                "_id": {
                    "$oid": "670027163e06b6b0ab6984a2"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063254340-3.jpg",
                "_id": {
                    "$oid": "670027163e06b6b0ab6984a3"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063254340-4.jpg",
                "_id": {
                    "$oid": "670027163e06b6b0ab6984a4"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 799,
        "c_description": "Blue printed ethnic dress has round neck, three quarter sleeves  and slip on closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:34:14.622Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:34:14.622Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670027353e06b6b0ab6984a7"
        },
        "c_name": "Floral Printed Ethnic Dresses With Shrug",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063285505-1.jpg",
                "_id": {
                    "$oid": "670027353e06b6b0ab6984a8"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063285505-2.jpg",
                "_id": {
                    "$oid": "670027353e06b6b0ab6984a9"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063285505-3.jpg",
                "_id": {
                    "$oid": "670027353e06b6b0ab6984aa"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063285505-4.jpg",
                "_id": {
                    "$oid": "670027353e06b6b0ab6984ab"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 3419,
        "c_description": "Yellow & Red Ethnic Dress With Shrug\nFloral Printed\nFit and Flared Shaped\nHas a Shrug\nV- neck\nThree-quarter regular sleeves\nFlared hem\nConcealed zip closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:34:45.791Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:34:45.791Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "670027573e06b6b0ab6984ae"
        },
        "c_name": "Ikat Printed Halter Neck Tiered Fit and Flare Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063319019-1.jpg",
                "_id": {
                    "$oid": "670027573e06b6b0ab6984af"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063319019-2.jpg",
                "_id": {
                    "$oid": "670027573e06b6b0ab6984b0"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063319019-3.jpg",
                "_id": {
                    "$oid": "670027573e06b6b0ab6984b1"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063319019-4.jpg",
                "_id": {
                    "$oid": "670027573e06b6b0ab6984b2"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 749,
        "c_description": "Magenta ethnic dress\nPrinted\nFit and flared shaped\nHalter-neck\nSleeveless\nFlared hem\nButton closure",
        "c_event": "Populer",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:35:19.308Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:35:19.308Z"
        },
        "__v": 0
    },
    {
        "_id": {
            "$oid": "6700279a3e06b6b0ab6984ba"
        },
        "c_name": "Printed Sleeveless Tiered Fit and Flare Maxi Ethnic Dresses",
        "c_multiImage": [
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063386534-1.jpg",
                "_id": {
                    "$oid": "6700279a3e06b6b0ab6984bb"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063386534-2.jpg",
                "_id": {
                    "$oid": "6700279a3e06b6b0ab6984bc"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063386534-3.jpg",
                "_id": {
                    "$oid": "6700279a3e06b6b0ab6984bd"
                }
            },
            {
                "i_url": "https://mamatamusicbanaras.com/image/item/1728063386534-4.jpg",
                "_id": {
                    "$oid": "6700279a3e06b6b0ab6984be"
                }
            }
        ],
        "c_brand": "DRESS",
        "c_price": 1462,
        "c_description": "Blue ethnic Dress\nPrinted\nFit & Flare Shaped\nMandarin Collar\nsleeveless\nFlared hem\nSlip on closure",
        "c_event": "Tranding",
        "c_url": "https://mamatamusicbanaras.com",
        "createdAt": {
            "$date": "2024-10-04T17:36:26.777Z"
        },
        "updatedAt": {
            "$date": "2024-10-04T17:36:26.777Z"
        },
        "__v": 0
    }
]

module.exports = data;