// src/components/AdSenseAd.js
import React, { useEffect } from 'react';
{/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7364280401206234"
     crossorigin="anonymous"></script>
<!-- TOP_HEAD -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-7364280401206234"
     data-ad-slot="6027225095"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script> */}
const AdSenseAd = () => {
    useEffect(() => {
        // Create a script element for Google AdSense
        const script = document.createElement('script');
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7364280401206234";
        script.async = true;
        script.crossOrigin = "anonymous";

        // Append the script to the body
        document.body.appendChild(script);

        // Create a timeout to push the ad after the script loads
        const timeoutId = setTimeout(() => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }, 500); // Delay to ensure the script has time to load

        return () => {
            clearTimeout(timeoutId);
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7364280401206234"
                data-ad-slot="6027225095"
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default AdSenseAd;
