import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import Navbar from "./Navbar";
import { PiCaretDoubleRight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import CFooter from "./CFooter";
import GoogleHeadAd from "../components/GoogleHeadAd";
import img1 from "../assets/images/brand/1728030731762-ethnic.png";
import img2 from "../assets/images/brand/1728030903546-western.png";
import img3 from "../assets/images/brand/1728031190031-lehnga.png";
import img4 from "../assets/images/brand/1728031307764-dress.png";
import img5 from "../assets/images/brand/1728031437228-saree.png";
import img6 from "../assets/images/brand/1728031614809-coords.png";
import img7 from "../assets/images/brand/1728031856297-jackes.png";
import img8 from "../assets/images/brand/1728032067224-shrugs.png";
import img9 from "../assets/images/brand/1728032288160-jump.png";
import img10 from "../assets/images/brand/1728043480332-trouser.png";

const CatData = [
  { name: "ETHNIC WEAR", image: img1 },
  { name: "WESTERN WEAR", image: img2 },
  { name: "LEHENGA CHOLI", image: img3 },
  { name: "DRESS", image: img4 },
  { name: "SAREE", image: img5 },
  { name: "CO-ORDS", image: img6 },
  { name: "JACKETS", image: img7 },
  { name: "SHRUGS", image: img8 },
  { name: "JUMPSUITS", image: img9 },
  { name: "WESTERN WEAR", image: img10 },
];

const MainHome = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleGoCategory = (name) => {
    navigate("/more/category/" + name);
  };
  const handleGoEvent = (name) => {
    navigate("/more/" + name);
  };
  const handleGoProduct = (id) => {
    navigate(`/shop/product/${id}`);
  };

  return (
    <>
      <div className="flex w-full justify-center  min-h-screen font-sans bg-white">
        <div className="w-[425px] h-auto bg-white">
          {loader ? (
            <Loader loader={loader} />
          ) : (
            <>
              <Navbar />
              <div className="py-4 px-3 mt-2">
                <h1 className="font-bold text-xl mb-2">Category</h1>
                <hr className="mb-4" />
                <div className="grid grid-cols-2 gap-x-3 my-3">
                  {CatData.map((cat, idx) => (
                    <div
                      key={idx}
                      className="my-3 shadow-2xl cursor-pointer transform transition-transform duration-500 hover:scale-105"
                    >
                      <div onClick={() => handleGoCategory(cat?.name)}>
                        <img
                          className="w-full h-auto object-contain transition-opacity duration-500 hover:opacity-90"
                          src={cat?.image}
                          alt={cat?.name}
                        />
                        <span className="absolute top-[-10px] right-2 bg-red-600 text-white font-bold text-xs py-1 px-2 rounded">
                          70% OFF
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-white py-4 px-3">
                <h1 className="font-bold text-xl mb-2">
                  Tranding
                  <span
                    className="text-primary-blue float-right text-sm mt-2 flex cursor-pointer"
                    onClick={(e) => handleGoEvent("Tranding")}
                  >
                    more <PiCaretDoubleRight className="mt-1 ms-1" />
                  </span>
                </h1>
                <hr className="mb-4" />
                <div className="grid grid-cols-2 gap-1 my-3">
                  <div className="overflow-hidden rounded-lg p-2 transform transition-transform duration-500 hover:scale-105">
                    <a
                      onClick={(e) =>
                        handleGoProduct("66ffd4ae584a4f778f5ae9dd")
                      }
                    >
                      <img
                        className="w-full h-auto object-contain transition-opacity duration-500 hover:opacity-90 cursor-pointer"
                        src="https://mamatamusicbanaras.com/image/item/1728042157624-1.jpg"
                        alt="name"
                      />
                      <span className="absolute top-2 right-2 bg-red-600 text-white font-bold text-xs py-1 px-2 rounded">
                        50% OFF
                      </span>
                    </a>
                  </div>
                  <div className="overflow-hidden rounded-lg p-2 transform transition-transform duration-500 hover:scale-105">
                    <a
                      onClick={(e) =>
                        handleGoProduct("66ffd611584a4f778f5aea6f")
                      }
                    >
                      <img
                        className="w-full h-auto object-contain transition-opacity duration-500 hover:opacity-90 cursor-pointer"
                        src="https://mamatamusicbanaras.com/image/item/1728042513292-1.jpg"
                        alt="name"
                      />
                      <span className="absolute top-2 right-2 bg-red-600 text-white font-bold text-xs py-1 px-2 rounded">
                        50% OFF
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bg-white py-4 px-3">
                <h1 className="font-bold text-xl mb-2">
                  Populer
                  <span
                    className="text-primary-blue float-right text-sm mt-2 flex cursor-pointer"
                    onClick={(e) => handleGoEvent("Populer")}
                  >
                    more <PiCaretDoubleRight className="mt-1 ms-1" />
                  </span>
                </h1>
                <hr className="mb-4" />
                <div className="grid grid-cols-2 gap-1 my-3">
                  <div className="overflow-hidden rounded-lg p-2 transform transition-transform duration-500 hover:scale-105">
                    <a
                      onClick={(e) =>
                        handleGoProduct("66ffdae5becb4652de39479f")
                      }
                    >
                      <img
                        className="w-full h-auto object-contain transition-opacity duration-500 hover:opacity-90 cursor-pointer"
                        src="https://mamatamusicbanaras.com/image/item/1728043749132-1.jpg"
                        alt="name"
                      />
                      <span className="absolute top-2 right-2 bg-red-600 text-white font-bold text-xs py-1 px-2 rounded">
                        50% OFF
                      </span>
                    </a>
                  </div>
                  <div className="overflow-hidden rounded-lg p-2 transform transition-transform duration-500 hover:scale-105">
                    <a
                      onClick={(e) =>
                        handleGoProduct("670020d88bbb02df023f3db5")
                      }
                    >
                      <img
                        className="w-full h-auto object-contain transition-opacity duration-500 hover:opacity-90 cursor-pointer"
                        src="https://mamatamusicbanaras.com/image/item/1728061655781-1.jpg"
                        alt="name"
                      />
                      <span className="absolute top-2 right-2 bg-red-600 text-white font-bold text-xs py-1 px-2 rounded">
                        50% OFF
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bg-white py-4 px-3">
                <h1 className="font-bold text-xl mb-2">
                  New Launch
                  <span
                    className="text-primary-blue float-right text-sm mt-2 flex cursor-pointer"
                    onClick={(e) => handleGoEvent("New Launch")}
                  >
                    more <PiCaretDoubleRight className="mt-1 ms-1" />
                  </span>
                </h1>
                <hr className="mb-4" />
                <div className="grid grid-cols-2 gap-1 my-3">
                  <div className="overflow-hidden rounded-lg p-2 transform transition-transform duration-500 hover:scale-105">
                    <a
                      onClick={(e) =>
                        handleGoProduct("67000f7fe4e9433c1c7fe99c")
                      }
                    >
                      <img
                        className="w-full h-auto object-contain transition-opacity duration-500 hover:opacity-90 cursor-pointer"
                        src="https://mamatamusicbanaras.com/image/item/1728057215348-1.jpg"
                        alt="name"
                      />
                      <span className="absolute top-2 right-2 bg-red-600 text-white font-bold text-xs py-1 px-2 rounded">
                        50% OFF
                      </span>
                    </a>
                  </div>
                  <div className="overflow-hidden rounded-lg p-2 transform transition-transform duration-500 hover:scale-105">
                    <a
                      onClick={(e) =>
                        handleGoProduct("67001ab28bbb02df023f3cab")
                      }
                    >
                      <img
                        className="w-full h-auto object-contain transition-opacity duration-500 hover:opacity-90 cursor-pointer"
                        src="https://mamatamusicbanaras.com/image/item/1728060082649-1.jpg"
                        alt="name"
                      />
                      <span className="absolute top-2 right-2 bg-red-600 text-white font-bold text-xs py-1 px-2 rounded">
                        50% OFF
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
          <CFooter />
        </div>
      </div>
    </>
  );
};

export default MainHome;
