import React from 'react'
import img1 from '../Image/payments_1.jpg';

const CFooter = () => {
    return (
        <footer className='py-4 mt-2'>
            <div>
                <img src={img1} />
            </div>
            <div className='container mx-auto text-center'>
                <label className='text-[10px]'>&copy; {new Date().getFullYear()} Your Store Name. All rights reserved.</label>
            </div>
        </footer>
    )
}

export default CFooter