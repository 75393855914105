import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import itemdata from "../data/itemdata"; // Import the data file
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Core Swiper styles
import "swiper/css/pagination"; // Pagination styles
import "swiper/css/navigation"; // Navigation styles
import "swiper/css/zoom"; // Zoom styles
import { Pagination, Navigation, Zoom } from "swiper/modules"; // Import required modules
import CFooter from "./CFooter";
import GoogleHeadAd from "../components/GoogleHeadAd";

const Product = () => {
  const [loader, setLoader] = useState(false);
  const U_ID = useLocation().pathname.split("/")[3]; // Get product ID from URL
  const [productData, setProductData] = useState(null); // Initial state for product data

  useEffect(() => {
    fetchProductData();
    // eslint-disable-next-line
  }, []);

  const fetchProductData = () => {
    setLoader(true);
    try {
      // Find product in local data based on the ID
      const product = itemdata.find((item) => item?._id?.$oid === U_ID);
      setProductData(product || null); // Set product data if found
    } catch (error) {
      console.error("Error fetching product data:", error); // Handle error
    } finally {
      setLoader(false); // Hide loader in any case
    }
  };

  // Loader display
  if (loader) {
    return (
      <div className="flex w-full justify-center bg-white min-h-screen font-sans">
        <Loader loader={loader} />
      </div>
    );
  }

  // Display if no product data is available
  if (!productData) {
    return (
      <div className="flex w-full justify-center bg-white min-h-screen font-sans">
        <h2 className="text-lg text-center">No Product Data Available</h2>
      </div>
    );
  }

  return (
    <div className="flex w-full justify-center min-h-screen font-sans bg-white">
      <div className="w-[425px] h-auto bg-white">
        <Navbar />
        <div className="py-4 px-3 mt-2 h-[80vh]">
          <h1 className="font-bold text-xl mb-2">{productData?.c_name}</h1>
          <hr className="mb-4" />

          {/* Swiper Image Slider with Zoom */}
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            zoom={true} // Enable zoom
            modules={[Zoom, Pagination, Navigation]} // Import necessary modules
            className="h-[300px] rounded-xl overflow-hidden"
          >
            {productData.c_multiImage.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container">
                  <img
                    src={image?.i_url}
                    alt={`Product ${index}`}
                    className="w-full h-full object-contain cursor-zoom-in"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Product Details */}
          <div className="my-4 w-full text-center">
            <a
              href={productData?.c_url}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 inline-block bg-primary-dark text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            >
              Buy Now
            </a>
          </div>
          <GoogleHeadAd />
        </div>
        <CFooter />
      </div>
    </div>
  );
};

export default Product;
